<template>
  <div class="rs-home" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="rs-cricket-banner-outer" v-if="section.static_section_id == 'banner'">
          <div class="rs-cricket-banner">
            <div class="rs-container pad-100">
              <div class="rs-flex-box banner">
                <div class="flex-item">
                  <h4 class="banner-title">{{section.detail.name}}
                    <div class="h1-animated js-spinner-parent" ref="bannerTitleSection">
                      <div class="h1-animated-row">
                        <span class="spinner-delayed spinner" data-spin-to="0">
                          <span class="spinner-container">
                            <span class="spinner-item hero-title">Live Score &amp;</span>
                            <span class="spinner-item hero-title">Cricket</span>
                            <!-- <span class="spinner-item hero-title">Conversational</span> -->
                            <span class="spinner-item hero-title">Analytics</span>
                            <span class="spinner-item hero-title">Cricket</span>
                          </span>
                          <span class="spinner-underline width-79"></span>
                        </span>
                      </div>
                      <div class="h1-animated-row">
                        <span class="spinner-delayed spinner" data-spin-to="0">
                          <span class="spinner-container">
                            <span class="spinner-item hero-title">Stats app</span>
                            <span class="spinner-item hero-title">Fantasy app</span>
                            <!-- <span class="spinner-item hero-title">chatbot</span> -->
                            <span class="spinner-item hero-title">Dashboard</span>
                            <span class="spinner-item hero-title">Blog</span>
                          </span>
                          <span class="spinner-underline width-75"></span>
                        </span>
                      </div>
                    </div>
                  </h4>
                  <div>
                    <div class="hero-links" v-if="section.detail.links && section.detail.links.length">
                      <template v-for="(link,index) in section.detail.links">
                        <TextButton :buttonType="index == 0 ? 'rs-button yellow-cta' : 'rs-button white-cta'"
                        :key="index" :link="link" />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="image-wrap-outer">
            <div class="image-wrap">
              <rz-image-item
                v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="client-logos-wrap" v-if="section.static_section_id == 'client_logos'">
          <div class="rs-container">
            <div>
              <h4>{{section.detail.name}}</h4>
              <div class="image-wrap">
                <img src="@/assets/rsCricket/client_logos.png" alt="Client logos"/>
              </div>
            </div>
          </div>
        </section>
        <section class="cricket-apis-wrap" v-if="section.static_section_id == 'apis'">
          <div class="rs-container">
            <rz-markdown class="hero-title max-width-670" v-if="section.detail.desc"
              :text="section.detail.desc"></rz-markdown>
            <div class="rs-flex-box">
              <ul class="api-tabs">
                <li class="on-mobile">
                  <div class="content-wrap on-mobile">
                     <div v-for="item in section.detail.items" :key="item.name" :id="item.name"
                      class="tabcontent" :class="'show'">
                      <span class="tablinks">
                        {{ item.name }}
                      </span>
                      <rz-markdown class="desc" v-if="item.desc"
                        :text="item.desc"></rz-markdown>
                      <div>
                        <h4 v-if="item.img && item.img.alt" class="image-title">{{item.img.alt}}</h4>
                        <div class="image-wrap">
                          <rz-image-item v-if="item.img" :image="item.img" />
                        </div>
                      </div>
                      <div>
                        <div class="hero-links" v-if="item.links.length">
                          <template v-for="link in item.links">
                            <TextButton :buttonType="'rs-button'" :key="link.name" :link="link" />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-for="tab in apiSections._values" :key="tab.key"
                class="on-tab"
                 :class="{active: tab == activeSection.value}">
                  <span class="tablinks"
                    @click="activeSection = apiSections.parse(tab)" v-if="tab">
                      {{tab}}
                  </span>
                </li>
              </ul>
              <div class="content-wrap on-desktop">
                <div v-for="item in section.detail.items" :key="item.name" :id="item.name"
                  class="tabcontent" :class="activeSection.value == item.name ? 'show' : 'hide'">
                  <rz-markdown class="desc" v-if="item.desc"
                    :text="item.desc"></rz-markdown>
                  <div>
                    <h4 v-if="item.img && item.img.alt" class="image-title">{{item.img.alt}}</h4>
                    <div class="image-wrap">
                      <rz-image-item
                        v-if="item.img" :image="item.img" />
                    </div>
                  </div>
                  <div>
                    <div class="hero-links" v-if="item.links.length">
                      <template v-for="link in item.links">
                        <TextButton :buttonType="'rs-button'" :key="link.name" :link="link" />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="client-testimonials-wrap" v-if="section.static_section_id == 'client_testimonials'">
          <div class="rs-container">
            <div class="title-wrapper">
              <rz-markdown class="hero-title max-width-670" v-if="section.detail.desc"
              :text="section.detail.desc"></rz-markdown>
              <img
                src="https://assets.sports.dev.roanuz.com/uploads-v5/cricket/website/banners/1apr2025/emoji-1.png"
                alt="client testimonial"/>
            </div>
            <div class="testimonial-wrapper" v-if="section.detail.items">
              <div v-for="item in section.detail.items" :key="item.name" class="testimonial">
                <div class="title-container">
                  <rz-image-item
                  v-if="item.img" :image="item.img" />
                  <rz-markdown v-if="item.name"
                  class="title"
                  :text="item.name"></rz-markdown>
                </div>
                <rz-markdown v-if="item.desc"
                class="desc"
                :text="item.desc"></rz-markdown>
              </div>
            </div>
          </div>
        </section>
        <section v-if="section.static_section_id == 'graph_promotion'" class="graph-promotion">
          <div class="rs-container">
            <rz-markdown class="title" v-if="section.detail.desc" :text="section.detail.desc" />
            <div class="graph-contents">
              <a :href="content.links[0].url"
                v-for="(content, i) in section.detail.items" :key="content.key" :class="'section-'+ i">
                <h3>{{content.name}}</h3>
                <rz-link-item class="link-button" :link="content.links[0]" />
                <rz-image-item v-if="content.img" :image="content.img" />
              </a>
            </div>
          </div>
        </section>
        <section v-if="section.static_section_id == 'discount_banner'" class="promo discount-banner">
          <div class="rs-container">
            <ContentImageWrap :section="section.detail" :sectionType="'discount'"
              :buttonType="'rs-button yellow-cta white'" :subText="section.name"
              :infoText="formatCurrency(minAmountForDiscount[currency], currency)"/>
          </div>
        </section>
        <section v-if="section.static_section_id == 'graphql_promo'" class="graphql-promo promo">
          <div class="rs-container">
            <div class="wrapper">
              <div class="content-wrapper">
                <h2>{{section.detail.name}}</h2>
                <rz-markdown class="hero-title" v-if="section.detail.desc"
                :text="section.detail.desc"></rz-markdown>
                <TextButton :buttonType="'rs-button'" :key="section.detail.links[0].name"
                :link="section.detail.links[0]" />
              </div>
              <div class="image-wrapper">
                <img src="@/assets/graphql-artwork.png" />
              </div>
            </div>
          </div>
        </section>
        <PromoBanner :section="section" v-if="section.template_id.isUseCases"/>
        <section v-if="section.static_section_id == 'kabbadiapi_promo'" class="promo kabbadiapi-banner">
          <div class="rs-container">
            <div class="container">
              <div class="wrapper">
                <div class="content-wrapper">
                  <h6>{{section.detail.links[1].name}}</h6>
                  <rz-markdown class="hero-title" v-if="section.detail.name"
                  :text="section.detail.name"></rz-markdown>
                  <rz-markdown class="hero-desc" v-if="section.detail.desc"
                  :text="section.detail.desc"></rz-markdown>
                  <TextButton :buttonType="'rs-button'" :key="section.detail.links[0].name"
                  :link="section.detail.links[0]" />
                </div>
                <div class="image-wrapper">
                  <rz-image-item
                    v-if="section.detail.img" :image="section.detail.img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pay-go-wrap resource-banner" v-if="section.static_section_id == 'resource_banner'">
          <div class="rs-container">
            <div class="rs-flex-box align-center">
              <div class="max-width-670">
                <rz-markdown class="hero-title" v-if="section.detail.name"
                :text="section.detail.name"></rz-markdown>
                <rz-markdown class="detail-text" v-if="section.detail.desc" :text="section.detail.desc"></rz-markdown>
                <div>
                  <div class="hero-links" v-if="section.detail.links && section.detail.links.length">
                    <template v-for="link in section.detail.links">
                      <TextButton :buttonType="'rs-button'" :key="link.key" :link="link" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- <section class="pay-go-wrap featured-wrap" v-if="section.static_section_id == 'featured_endpoints'">
          <div class="featured-apis-wrap" v-if="resources && resourceLoaded">
            <div class="rs-container">
              <h4 class="title">{{section.name}}</h4>
              <div class="rs-flex-box">
                <div class="flex-item inner-box" v-for="(item, index) in section.detail.items" :key="index" :id="index">
                  <div v-if="item">
                    <div v-for="(endpoints, key) in resources" :key="key">
                      <template v-for="api in endpoints">
                        <div class="flex-box-inner" :key="api.name" v-if="api.name == item.name">
                          <div class="block-left">
                            <router-link
                              class="end-point-link"
                              :to="{name: 'rsResourcePage', params: { api: api.resourceKey.split('_').join('-') }}">
                                {{ item.name }} API
                            </router-link>
                            <rz-markdown class="text" v-if="item.desc" :text="item.desc"></rz-markdown>
                          </div>
                          <div class="block-right">
                            <div class="pricing-info">
                              <div class="make-bold">
                                <span v-if="api && api.detail && api.detail.MG100 && api.detail.MG100.price
                                  && api.detail.MG100.price.normal_price
                                  && api.detail.MG100.price.normal_price.enterprise &&
                                  api.detail.MG100.price.normal_price.enterprise[currency] >= 0" :key="key">
                                  {{formatCurrency(api.detail.MG100.price.normal_price.enterprise[currency], currency)}}
                                </span>
                              </div>
                              <div class="per-month" v-if="api.starts_at">
                                <span class="tooltip">{{api.starts_at.unit_label}}</span>
                                <span class="tooltip"> with <a href="/v5/pricing">Enterprise Plan</a></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pay-go-wrap" v-if="section.static_section_id == 'end_points'">
          <div v-if="resources && resourceLoaded">
            <div class="rs-container pad-10">
              <div class="flex-item endpoint hide"
               v-for="(endpoints, key, index) in resources" :key="key"
                :class="key + '-section'" :id="'endpoint-' + index">
                <div class="flex-item-header" @click="collapseSection(key)">
                  <div class="flex-box clickable">
                    <h4 class="title">{{key}} Endpoints</h4>
                    <span class="expand"></span>
                  </div>
                  <p class="text">
                    <span v-for="(api, index) in endpoints" :key="api.name">
                      {{api.name}}<span v-if="index + 2 == endpoints.length">
                        and&nbsp;
                      </span>
                      <span v-else-if="index + 1 == endpoints.length"></span>
                      <span v-else>,&nbsp;</span>
                    </span>
                  </p>
                </div>
                <div class="rs-flex-box">
                  <template v-for="item in section.detail.items">
                    <template v-for="(api, index) in endpoints">
                      <div class="flex-item inner-box check"
                        :class="{ 'show-border' : showBorder(index, endpoints.length)}"
                        v-if="api.name == item.name" :key="item.name || api.name">
                        <div class="flex-box-inner">
                        <div class="block-left">
                        <router-link
                          class="end-point-link color-blue"
                          :to="{name: 'rsResourcePage', params: { api: api.resourceKey.split('_').join('-') }}">
                            {{ item.name }} API
                        </router-link>
                        <rz-markdown class="text" v-if="item.desc" :text="item.desc"></rz-markdown>
                        </div>
                        <div class="block-right">
                          <div class="pricing-info endpoints-section">
                            <div class="make-bold">
                              <span v-if="api && api.detail && api.detail.MG100 &&
                                api.detail.MG100.price && api.detail.MG100.price.normal_price &&
                                api.detail.MG100.price.normal_price.enterprise &&
                                api.detail.MG100.price.normal_price.enterprise[currency] >= 0" :key="key">
                                {{formatCurrency(api.detail.MG100.price.normal_price.enterprise[currency], currency)}}
                              </span>
                            </div>
                            <div class="per-month" v-if="api.starts_at">
                              <span class="tooltip">{{api.starts_at.unit_label}}</span>
                              <span class="tooltip"> with <a href="/v5/pricing">Enterprise Plan</a>
                              </span>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </section> -->
        <section class="rs-container table package-based"
          id="package-based"
          v-if="section.static_section_id == 'package_based'">
          <div class="content-section">
            <rz-markdown :text="section.detail.name" class="title"></rz-markdown>
            <rz-markdown class="content" :text="section.detail.desc"></rz-markdown>
          </div>
          <PackageBasedPricing :packagePlans="packagePlans" :currency="currency"
           @showPopup="showPopup($event)" :showStandardPackage="true" />
        </section>
        <section class="customers-wrap-outer" v-if="section.static_section_id == 'customers'">
          <div class="max-width-1440">
            <div class="rs-container">
              <CustomersSection :section="section" :isRspage="true"/>
            </div>
            <div class="image-wrap hide-on-mobile">
              <picture>
                <source media="(max-width: 767px)" srcset="@/assets/rsCricket/variations/customers_apps.png">
                <source media="(min-width: 768px)" srcset="@/assets/rsCricket/variations/customers_apps@2x.png">
                <source media="(min-width: 1194px)" srcset="@/assets/rsCricket/variations/customers_apps@3x.png">
                <img src="@/assets/rsCricket/variations/customers_apps@3x.png" alt="banner">
              </picture>
              <!-- <img src="@/assets/rsCricket/customer-banner@3x.png" alt="banner"/> -->
            </div>
          </div>
        </section>
        <section class="tech-process-wrap" v-if="section.static_section_id == 'tech_process'">
          <div class="rs-container">
            <h3 class="rs-section-title">Top-notch tech &amp; Process</h3>
            <rz-markdown class="hero-title  max-width-670" v-if="section.name"
            :text="section.name"></rz-markdown>
            <div class="rs-flex-box">
              <div class="flex-item" v-for="section in section.detail.items" :key="section.name">
                <content-section
                  :section="section"
                  :showButton="false"
                  :sectionType="'tech-process-wrap rs-page'"/>
              </div>
            </div>
          </div>
        </section>
        <section class="press-mentions"
          :class="section.static_section_id"
          v-if="section.static_section_id == 'press_mentions' || section.static_section_id == 'matches'">
          <div class="rs-container"  v-if="section.static_section_id == 'press_mentions'">
            <div class="left-part">
              <h1 class="rs-section-title">Press Mentions</h1>
              <h2 class="hero-title max-width-670">{{section.name}}</h2>
              <div class="rs-flex-box">
                <div class="column-box">
                  <template v-for="(item,index) in section.detail.items">
                    <a v-for="(link, i) in item.links" :href="link.url"
                      :key="index || 'link'+i" target="_blank" rel="noopener" class="transparent-link">
                      {{index}} <!-- This is a quick fix for "Links do not have a discernible name" test. -->
                      <div class="display-item" v-if="index%2 == 1">
                        <p class="date">{{item.name}}</p>
                        <div class="image-wrap press-image">
                          <rz-image-item
                          v-if="item.img" :image="item.img" />
                        </div>
                        <p class="detail-text">{{item.desc}}</p>
                        <div>
                          <div class="hero-links" v-if="item.links.length">
                            <template v-for="link in item.links">
                              <TextButton :key="link.name" :link="link" :buttonType="'rs-button plain-cta'"/>
                            </template>
                          </div>
                        </div>
                      </div>
                    </a>
                  </template>
                </div>
                <div class="column-box">
                  <template v-for="(item,index) in section.detail.items">
                    <a v-for="(link, i) in item.links" :href="link.url"
                      :key="index || 'link'+i" target="_blank" rel="noopener" class="transparent-link">
                      {{index}} <!-- This is a quick fix for "Links do not have a discernible name" test. -->
                      <div class="display-item" v-if="index%2 == 0">
                        <p class="date">{{item.name}}</p>
                        <div class="image-wrap press-image">
                          <rz-image-item
                          v-if="item.img" :image="item.img" />
                        </div>
                        <p class="detail-text">{{item.desc}}</p>
                        <div>
                          <div class="hero-links" v-if="item.links.length">
                            <template v-for="link in item.links">
                              <TextButton :key="link.name" :link="link" :buttonType="'rs-button plain-cta'"/>
                            </template>
                          </div>
                        </div>
                      </div>
                    </a>
                  </template>
                </div>
              </div>
              <TextButton class="hide" :buttonType="'rs-button plain-cta'" :link="pressLinks" />
            </div>
          </div>
          <div class="matches-wrap" v-if="section.static_section_id == 'matches'">
            <div class="match-box" v-for="item in section.detail.items" :key="item.name">
              <h3>
                <template v-for="(link, i) in item.links">
                  <a :href="link.url" :key="i" >
                    <rz-link-item class="link-button" :link="link" />
                    <rz-markdown class="detail-text" v-if="item.desc" :text="item.desc"></rz-markdown>
                  </a>
                </template>
              </h3>
            </div>
            <TextButton :buttonType="'rs-button white-cta plain'" :link="newsLinks" />
          </div>
        </section>
        <section class="rs-container pad-100 get-started" v-if="section.static_section_id == 'get_started'">
          <get-started-section />
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
          <div class="rs-container">
            <support-wrapper />
          </div>
        </section>
        <section class="we-spear-your-language" v-if="section.static_section_id == 'speak_language'">
          <div class="rs-container">
            <rz-markdown class="hero-title" v-if="section.detail.name"
            :text="section.detail.name"></rz-markdown>
            <div class="rs-flex-box no-wrap">
              <div class="max-width-750">
                <rz-markdown class="detail-text level4" v-if="section.detail.desc"
                  :text="section.detail.desc"></rz-markdown>
                <div>
                  <div class="hero-links" v-if="section.detail.links && section.detail.links.length">
                    <template v-for="link in section.detail.links">
                      <TextButton :key="link.name" :link="link" :buttonType="'rs-button plain-blue-cta'"/>
                    </template>
                  </div>
                </div>
              </div>
              <div class="flex-item">
                <TextButton :buttonType="'rs-button'" :link="docs" />
              </div>
            </div>
          </div>
        </section>
        <section v-if="section.static_section_id == 'push_notifications'">
          <div class="rs-container">
            <ContentImageWrap :section="section.detail" />
          </div>
        </section>
        <PaymentSignupSection
            v-if="section.static_section_id == 'singup_wrapper'"
            :signupInfo="section"
          />
      </section>
    </div>
    <div class="popup-wrapper" v-if="openPopup" @click.self="closePopup()">
      <div class="popup" id="popup">
        <div class="popup-header">
          <svg @click="closePopup()"><use v-bind="{'xlink:href':'#close-btn'}"></use></svg>
        </div>
       <PackagePopup :planInfo="planInfo" :currency="currency"/>
      </div>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Enum } from '@/core/utils/enum';
import { minAmountForDiscount, supportNumbers } from '@/store/modules/page';
import CustomersSection from '@/components/RZWebsite/CustomersSection.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import { PayGoResources, Package } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import GetStartedSection from '@/components/RsCricket/GetStartedSection.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import PromoBanner from '@/components/RsCricket/PromoBanner.vue';
import ContentImageWrap from '@/components/RsCricket/ContentImageWrap.vue';
import PackageBasedPricing from '@/components/PackageBasedPricing.vue';
import PackagePopup from '@/components/PackagePopup.vue';
import packageBasedData from '../../../stories/PackageBasedPlans.json';

export const ApiSection = new Enum({
  LiveScore: 'Live Cricket Scores & Stats',
  Chatbot: 'Cricket Intelligence',
  Performance: 'Conversational AI',
  Fantasy: 'Fantasy Sports',
});

export default {
  name: 'rsCricketHome',
  components: {
    CustomersSection,
    ContentSection,
    CricketLoadingPlaceholder,
    TextButton,
    GetStartedSection,
    SupportWrapper,
    ContentImageWrap,
    PaymentSignupSection,
    PromoBanner,
    PackageBasedPricing,
    PackagePopup,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      committedUseItems: (state) => state.payments.wizard.committedUseItems,
      currency: (state) => state.user.activeCurrency,
      rsFooterLinks: (state) => state.link.rsFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchResoucePricing();
    });
  },
  data() {
    return {
      resources: null,
      api_usage: null,
      resourceLoaded: false,
      previousTabId: null,
      docs: {
        name: 'Read the Docs',
        routeParams: { name: 'docsHome' },
      },
      pressLinks: {
        name: 'More press release',
        url: '/',
      },
      newsLinks: {
        name: 'More news',
        routeParams: { name: 'rsBlogList' },
      },
      activeSection: ApiSection.LiveScore,
      apiSections: ApiSection,
      minAmountForDiscount,
      packageBasedData,
      openPopup: false,
      planInfo: null,
      supportNumbers,
      packagePlans: null,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      console.log('data loaded home vue');
      this.fetchResoucePricing().then(() => {
        this.collapseSection('match');
      });
    }

    this.bannerAnimation();
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchResoucePricing().then(() => {
          this.collapseSection('match');
        });
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    fetchResoucePricing() {
      return PayGoResources.getResourcePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.resources = resp.groupByEndpoints;
        this.resourceLoaded = true;
        this.api_usage = resp.usageInfo;
        this.fetchPackagePricing();
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    fetchPackagePricing() {
      return Package.getPackagePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.packagePlans = resp.data.data.response.filter((plan) => plan.show_in_website && plan.active);
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    collapseSection(key) {
      const element = document.getElementsByClassName(`${key}-section`);
      if (element.length) {
        const contentContainer = element[0].querySelector('.rs-flex-box');
        if (element && element[0]) {
          if (element[0].classList.contains('hide')) {
            element[0].classList.remove('hide');
            contentContainer.style.height = `${contentContainer.scrollHeight}px`;
          } else {
            element[0].classList.add('hide');
            contentContainer.setAttribute('style', '');
          }
        }
      }
    },
    bannerAnimation() {
      setInterval(() => {
        clearInterval();
        this.wordWrapAnimation();
      }, 7000);
    },
    wordWrapAnimation() {
      const bannerTitles = document.getElementsByClassName('spinner-delayed');
      bannerTitles.forEach((elID) => {
        const underline = elID.children[1];
        const items = elID.querySelectorAll('.spinner-item');
        let index = 0;
        underline.style.width = this.calcWidth(items[0].offsetWidth, elID.offsetWidth);
        setTimeout(() => {
          setInterval(() => {
            const item = items[index];
            if (item) {
              elID.setAttribute('data-spin-to', index);
              underline.style.width = this.calcWidth(item.offsetWidth, elID.offsetWidth);
              // eslint-disable-next-line no-plusplus
              index++;
            }
          }, 1500);
        }, 2500);
      });
    },
    calcWidth(a, b) {
      // eslint-disable-next-line no-mixed-operators
      return `${a / b * 100}%`;
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    showBorder(index, arrSize) {
      if (
        arrSize % 2 === 1
        && arrSize > 2
        && index === arrSize - 2
      ) {
        return true;
      }
      return false;
    },
    showPopup(plan) {
      this.planInfo = plan;
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'hidden';
    },
    closePopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'auto';
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-home {
  font-family: var(--rz-hero-font);
  max-width: size(2560);
  margin: 0 auto;
  overflow: hidden;
  .promo-banner {
    .wrapper {
      display: block;
      background: var(--rz-title-color);
      border-radius: size(6);
    }
    .rs-container {
      position: relative;
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: 0;
      }
      .container-wrapper {
        padding: size(50) size(20);
        text-align: center;
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(40) size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(60) size(110);
        }
      }
      .heading-wrapper {
        max-width: size(300);
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(390);
        }
        h2 {
          font-weight: bold;
          font-size: size(22);
          line-height: size(30);
          text-transform: uppercase;
          color: var(--rs-white-color);
          background: #FF0182;
          padding: size(6);
          text-align: center;
          @media screen and (min-width: $breakpoint-lg) {
            font-size: size(30);
            line-height: size(42);
            padding: size(10) size(18);
          }
          &:last-child {
            background: #33C84B;
            max-width: size(230);
            margin: 0 auto;
            @media screen and (min-width: $breakpoint-lg) {
              max-width: size(265);
            }
          }
        }
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding-top: size(40);
        @media screen and (min-width: $breakpoint-md) {
          padding-left: size(30);
          padding-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(115);
        }
        p {
          font-weight: bold;
          font-size: size(16);
          line-height: size(28);
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: var(--rs-white-color);
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(20);
            line-height: size(32);
          }
        }
        .info {
          padding-bottom: size(26);
          letter-spacing: none;
          span {
            font-weight: bold;
            font-size: size(16);
            line-height: size(28);
            color: var(--rs-white-color);
            display: block;
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(20);
              line-height: size(32);
              display: inline-block;
            }
            &:before {
              content: '';
              display: inline-block;
              width: size(12);
              height: size(12);
              background: #33C84B;
              border-radius: 50%;
              margin-right: size(14);
              vertical-align: top;
              margin-top: size(7);
              @media screen and (min-width: $breakpoint-md) {
                margin-top: size(12);
              }
            }
            &:last-child {
              @media screen and (min-width: $breakpoint-md) {
                padding-left: size(14);
              }
              &::before {
                background: #FF0182;
              }
            }
          }
        }
        .coverage {
          font-size: size(16);
          line-height: size(22);
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #33C84B;
          padding-top: size(10);
          @media screen and (min-width: $breakpoint-md) {
            padding-top: 0;
          }
        }
      }
    }
  }
  .promo {
    .rs-container {
      padding-top: size(32);
    }
    &.discount-banner {
      .rs-container {
        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: 0;
        }
      }
    }
  }
  .graphql-promo {
    .rs-container {
      padding-bottom: size(45);
      .wrapper {
        background: #141E27;
        border-radius: size(6);
        padding: size(50) size(20);
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(60) size(60);
        }
        .content-wrapper {
          display: inline-block;
          vertical-align: top;
          @media screen and (min-width: $breakpoint-md) {
            width: 48%;
          }
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(440);width: 50%;
          }
          .text-img {
            display: inline-block;
            width: size(52);
            padding-bottom: size(10);
          }
          h2 {
            font-weight: bold;
            font-size: size(30);
            line-height: size(42);
            color: var(--rs-black-color);
            padding-bottom: size(10);
            padding-right: 0;
            color: #58CFDB;
            &.intro-text {
              color: #F5FF78;
              padding-bottom: 0;
            }
          }
        }
        .image-wrapper {
          display: inline-block;
          width: auto;
          vertical-align: top;
          @media screen and (min-width: $breakpoint-md) {
            width: 48%;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: 50%;
          }
          img {
            display: inline-block;
            padding-top: size(30);
            @media screen and (min-width: $breakpoint-md) {
              object-fit: cover;
              padding-left: size(25);
              padding-top: size(85);
            }
            @media screen and (min-width: size(1024)) {
              padding-top: 0;
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(428);
              padding-left: size(80);
              padding-top: 0;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
  .rs-cricket-banner-outer {
    position: relative;
    .image-wrap-outer {
      position: absolute;
      right: 0;
      bottom: size(100);
      .image-wrap {
        width: size(220);
        height: size(245);
        margin: 0 auto;
      }
    }
    .js-spinner-parent {
      margin-top: size(20);
    }
    @media screen and (min-width: $breakpoint-md) {
      .image-wrap-outer {
        top: initial;
        bottom: 0;
        right: size(-125);
        .image-wrap {
          width: size(465);
          height: size(335);
        }
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .image-wrap-outer {
        top: size(110);
        bottom: initial;
        left: size(730);
        right: 0;
        .image-wrap {
          width: size(775);
          height: size(875);
        }
      }
    }
    @media screen and (min-width: size(1400)) {
      .image-wrap-outer {
        .image-wrap {
          width: size(936);
          height: size(924);
        }
      }
    }
    @media screen and (min-width: size(1441)) {
      .image-wrap-outer {
        left: size(850);
        .image-wrap {
          width: size(830);
          height: size(830);
        }
      }
    }
    @media screen and (min-width: size(2200)) {
      .image-wrap-outer {
        left: size(1000);
      }
    }
  }
  .rs-cricket-banner {
    background: linear-gradient(180deg, #1F27A3 0%, #31319A 100%);
    padding-bottom: size(200);
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0 70%);
    min-height: size(550);
    h4 {
      font-size: var(--rs-title-level1-font-size);
      line-height: var(--rs-title-level1-line-height);
      color: var(--rs-white-color);
      font-weight: bold;
    }
    .hero-title {
      color: var(--rs-white-color);
      text-decoration: underline;
      padding-right: 0;
    }
    .hero-links {
      padding-top: size(20);
    }
    .rs-container {
      &.pad-100 {
        padding-top: size(50);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      min-height: size(550);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 75%);
      .flex-item {
        flex: 0 0 60%;
      }
      .rs-container {
        &.pad-100 {
          padding-top: size(100);
        }
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
    }
  }
  .client-logos-wrap {
    z-index: 9;
    border-bottom: size(1) dashed var(--rs-gray-color);
    padding-bottom: size(50);
    transform: translateY(size(-65));
    h4 {
      font-size: var(--rs-title-level3-font-size);
      line-height: var(--rs-title-level3-line-height);
      font-weight: bold;
      max-width: size(480);
    }
    .rs-container {
      padding: 0 size(20);
    }
    @media screen and (min-width: $breakpoint-md) {
      .image-wrap {
        width: size(592);
        height: size(250);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .rs-container {
        padding: 0;
      }
    }
  }
  .customers-wrap-outer {
    position: relative;
    border-top: size(1) dashed var(--rs-gray-color);
    overflow: hidden;
    .rs-container {
      padding-top: 0;
    }
    .image-wrap {
      position: absolute;
      top: size(-30);
      right: size(-79);
      background-image: url(../../assets/rsCricket/blue-ring.png);
      background-repeat: no-repeat;
      background-position: left top size(30);
      max-width: size(415);
      height: size(295);
      background-size: contain;
    }
    @media screen and (min-width: $breakpoint-lg) {
      .image-wrap {
        right: size(-200);
        max-width: size(877);
        height: size(540);
        background-size: initial;
      }
    }
  }
  .tech-process-wrap {
    .flex-item {
      padding-right: size(30);
      padding-bottom: size(40);
    }
    .rs-flex-box {
      padding-top: size(40);
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(30);
      .flex-item {
        flex: 0 0 45%;
      }
    }
     @media screen and (min-width: $breakpoint-lg) {
      .flex-item {
        flex: 0 0 30%;
      }
    }
  }
  .cricket-apis-wrap {
    color: var(--rs-black-color);
    .image-wrap {
      height: size(60);
      padding-bottom: size(5);
    }
    .hero-title {
      display: none;
      @media screen and (min-width: $breakpoint-md) {
        display: block;
      }
    }
    .image-title {
      font-size: var(--rs-title-level5-font-size);
      line-height: var(--rs-title-level5-line-height);
      font-weight: bold;
      padding-top: size(30);
      padding-bottom: size(10);
    }
    .api-tabs {
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(40);
      }
      li {
        @media screen and (min-width: $breakpoint-md) {
          &.active {
            .tablinks {
              color: var(--rs-cta-blue-color);
              &:after {
                content: '';
                border: solid var(--rs-cta-blue-color);
                border-width: 0 size(2) size(2) 0;
                display: inline-block;
                padding: size(3);
                transform: rotate(45deg);
                margin-left: size(10);
                vertical-align: middle;
                @media screen and (min-width: $breakpoint-md) {
                  transform: rotate(-45deg);
                  margin-left: size(5);
                }
              }
            }
          }
        }
        &:last-child {
          .content-wrap {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
        .tablinks {
          display: inline-block;
          vertical-align: sub;
          padding-right: size(10);
          font-size: var(--rs-title-level2-font-size);
          line-height: var(--rs-title-level2-line-height);
          font-weight: bold;
          color: var(--rs-black-color);
          padding-bottom: size(10);
          cursor: pointer;
        }
        .content-wrap, .tabcontent {
          padding-top: 0;
          padding-bottom: size(30);
          margin-bottom: size(30);
          border-bottom: size(1) solid #C4C4C4;
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: size(30);
            border-bottom: none;
          }
        }
        .content-wrap.on-mobile {
          padding-top: size(0);
          padding-bottom: size(0);
          margin-bottom: size(0);
          border-bottom: size(30);
        }
        .content-wrap {
          .tabcontent:last-child {
            padding-bottom: size(0);
            margin-bottom: size(0);
            border-bottom: size(0);
          }
        }
        &.on-mobile {
          @media screen and (min-width: $breakpoint-md) {
            display: none;
          }
        }
        &.on-tab {
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
          }
        }
      }
    }
    .rs-flex-box {
      justify-content: space-between;
    }
    .rs-container {
      padding-top: size(0);
    }
    .content-wrap {
      padding-top: size(50);
      &.on-desktop {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: unset;
        }
      }
      &.on-mobile {
        @media screen and (min-width: $breakpoint-md) {
          display: none;
        }
      }
      .hide {
        display: none;
      }
      .desc {
        font-size: var(--rs-title-level4-font-size);
        line-height: var(--rs-title-level4-line-height);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      .api-tabs {
        flex: 0 0 45%;
      }
      .content-wrap {
        flex: 0 0 50%;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .api-tabs {
        flex: auto;
      }
      .content-wrap {
        flex: 0 0 55%;
      }
    }
  }
  .detail-text {
    font-size: var(--rs-para-font-size);
    line-height: var(--rs-para-line-height);
    padding-top: size(20);
    display: inline-block;
    &.level4 {
      padding-top: 0;
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      padding-right: size(40);
      @media screen and (min-width: $breakpoint-md) {
        border-right: size(1) solid;
      }
    }
  }
  .max-width-750 {
    max-width: size(750);
  }
  .max-width-670 {
    max-width: size(670);
    display: block;
  }
  .we-spear-your-language {
    .rs-flex-box {
      padding-top: size(35);
    }
    .hero-links {
      padding-top: size(20);
    }
    @media screen and (min-width: $breakpoint-md) {
      .flex-item {
        padding-left: size(70);
      }
    }
  }
  .press-mentions {
    background: var(--rs-light-blue-color);
    padding: 0 size(20);
    position: relative;
    max-width: size(1440);
    margin: 0 auto;
    .hide {
      display: none;
    }
    &.matches {
      position: relative;
      height: size(1100);
      margin-bottom: size(100);
      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: 0;
        height: auto
      }
      @media screen and (max-width: $breakpoint-sm) {
        .matches-wrap {
          position: absolute;
          left: size(20);
          right: size(20);
        }
      }
    }
    .image-wrap {
      max-width: size(140);
      height: size(25);
    }
    .hero-title {
      padding-bottom: size(40);
    }
    .rs-container {
      padding: size(70) 0;
    }
    .display-item {
      background: var(--master-text);
      box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
      border-radius: size(6);
      padding: size(40) size(40) size(25);
      display: block;
      transition: all .3s ease-in-out;
      margin-bottom: size(8);
      &:hover {
        box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.5rem 1.5rem, rgba(17, 17, 26, 0.1) 0rem 1rem 3.5rem,
        rgba(17, 17, 26, 0.1) 0rem 1.5rem 5rem;
      }
      @media screen and (min-width: $breakpoint-md) {
        margin-right: size(20);
      }
    }
    .detail-text {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      color: var(--rs-black-color);
      padding-top: size(15);
    }
    .date {
      font-size: var(--rs-sm-label-font-size);
      line-height: var(--rs-sm-label-line-height);
      font-weight: bold;
      color: var(--rs-gray-color);
      padding-bottom: size(20);
      display: none;
    }
    .matches-wrap {
      max-width: size(390);
      background: var(--rs-black-color);
      border-radius: size(6);
      color: var(--rs-white-color);
      padding: size(40);
      .detail-text {
        color: var(--rs-white-color);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      .left-part {
        display: initial;
        flex: 0 0 43%;
      }
      .rs-container {
        display: flex;
      }
      .matches-wrap {
        position: absolute;
        right: size(20);
        top: size(-1530);
        padding: size(110) size(40) size(70);
        max-width: size(320);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0 size(30);
      .left-part {
        flex: initial;
      }
      .column-box {
        flex: 0 0 30%;
      }
      .rs-container {
        margin: 0;
      }
      .matches-wrap {
        position: absolute;
        right: size(40);
        top: size(-1050);
        padding: size(110) size(40) size(70);
      }
    }
    @media screen and (min-width: size(1400)) {
      padding: 0 size(150);
      .rs-container {
        margin: 0 auto;
      }
      .matches-wrap {
        position: absolute;
        right: size(100);
        top: size(-1050);
        padding: size(110) size(40) size(70);
      }
    }
    @media screen and (min-width: size(1700)) {
      .matches-wrap {
        right: size(250);
      }
    }
  }

  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
}

.spinner {
  position: relative;
  top: size(6.4);
  transition: flex .5s;
  &-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: size(42);
    overflow: hidden;
    position: relative;
    width: 100%;
    @media screen and (min-width: $breakpoint-md) {
      height: size(90);
    }
  }
  &-item {
    display: block;
    position: relative;
    transform: translateY(100%);
    transition-duration: .5s;
    transition-property: transform;
    white-space: nowrap;
    top: size(-5);
    text-decoration: none !important;
  }
  &-underline {
    &.width-75 {
      width: 75%;
    }
    &.width-79 {
      width: 79%;
    }
    background: var(--rs-white-color);
    bottom: -5%;
    content: "";
    display: block;
    height: size(4);
    left: 0;
    max-width: 100%;
    position: absolute;
    transition:width .25s;
    width: 0;
  }
  &-delayed {
    .spinner-item {
      transition-delay: .125s;
    }
  }
  &[data-spin-to="0"] {
    .spinner-container .spinner-item {
      transform: translateY(0%);
    }
  }
  &[data-spin-to="1"] {
    .spinner-container .spinner-item {
      transform: translateY(-100%);
    }
  }
  &[data-spin-to="2"] {
    .spinner-container .spinner-item {
      transform: translateY(-200%);
    }
  }
  &[data-spin-to="3"] {
    .spinner-container .spinner-item {
      transform: translateY(-300%);
    }
  }
  &[data-spin-to="4"] {
    .spinner-container .spinner-item {
      transform: translateY(-400%);
    }
  }
}

.h1-animated-row {
  align-items: baseline;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: size(18);
  & >* {
    flex:0 0 0;
    margin:0 size(3.2);
  }
}
.pay-go-wrap {
  &.resource-banner {
    padding-bottom: 0;
    .rs-flex-box {
      display: block;
    }
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(80);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(120);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      .rs-flex-box {
        display: flex;
      }
    }
  }
  .align-center {
    flex-wrap: nowrap;
  }
}
.home-page {
  .pay-go-wrap {
    .expand {
      width: initial;
    }
  }
}
.transparent-link {
  color: transparent;
}
</style>
<style lang="scss">
.rs-home {
  #package-based {
    padding-top: size(60);
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: 0 0 size(30);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: 0;
      padding-bottom: size(60);
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: var(--rs-title-level2-font-size);
        line-height: var(--rs-title-level2-line-height);
        padding-bottom: size(10);
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
  }
  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }
  .match-box {
    padding: size(20) 0;
    border-bottom: size(1) solid #3F3F3F;
    .link-button {
      .link-plain {
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        text-transform: uppercase;
        color: var(--rs-light-lavender-color);
      }
    }
    &:nth-child(2n) {
      .link-plain {
        color: var(--rs-orange-color);
      }
    }
    &:nth-child(3n) {
      .link-plain {
        color: var(--rs-light-pink-color);
      }
    }
    &:nth-child(4n) {
      .link-plain {
        color: var(--rs-light-green-color);
      }
    }
    &:nth-child(5n) {
      .link-plain {
        color: var(--rs-blue-color);
      }
    }
  }
  .cricket-apis-wrap {
    .hero-title {
      strong {
        color: var(--rs-pink-color);
      }
    }
  }
  .pay-go-wrap {
    .hero-title {
      strong {
        color: var(--rs-lavender-color);
      }
    }
  }
  .tech-process-wrap {
    .hero-title {
      strong {
        color: var(--rs-red-color);
      }
    }
    .flex-item {
      padding-right: size(30);
      padding-bottom: size(40);
    }
    .rs-flex-box {
      padding-top: size(40);
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(30);
      .flex-item {
        flex: 0 0 30%;
      }
    }
  }
  .we-spear-your-language {
    .hero-title {
      strong {
        color: var(--rs-green-color);
        &:after {
          content: '';
          display: inline-block;
          background-image: url(../../assets/rsCricket/speak.svg);
          width: size(32);
          height: size(70);
          background-repeat: no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-left: size(10);
          @media screen and (min-width: $breakpoint-md) {
            height: size(103);
          }
        }
      }
    }
  }
  .committed-usage {
    .rs-container {
      padding-top: size(0);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(160);
      }
    }
  }
  .kabbadiapi-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding: 0;
      }
      .container {
        background: linear-gradient(180deg, #081953 0%, #1F52A5 100%);
        padding-top: 0;
        border-radius: size(6);
      }
      .wrapper {
        background: url('../../assets/arena.svg') no-repeat;
        background-size: 100%;
        position: relative;
        background-position: center;
        @media screen and (min-width: $breakpoint-md) {
          background-position: top;
        }
        .content-wrapper {
          display: inline-block;
          vertical-align: top;
          padding: size(50) size(20);
          padding-bottom: size(180);
          @media screen and (min-width: $breakpoint-md) {
            width: size(310);
            padding: size(60) size(20);
            padding-right: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(310);
            padding: size(65) size(60);
            padding-right: 0;
          }
          h6 {
            font-size: size(16);
            line-height: size(19);
            color: var(--rs-white-color);
            padding-bottom: size(8);
          }
          .hero-title {
            .p {
              font-weight: bold;
              font-size: size(30);
              line-height: size(42);
              padding-bottom: size(20);
              padding-right: 0;
              color: var(--rs-white-color);
              @media screen and (min-width: $breakpoint-md) {
                font-size: size(32);
                line-height: size(39);
              }
            }
          }
          .hero-desc {
            .p {
              font-size: size(20);
              line-height: size(28);
              color: var(--rs-white-color);
              padding-bottom: size(20);
              @media screen and (min-width: $breakpoint-md) {
                padding-bottom: size(36);
              }
              strong {
                font-weight: bold;
              }
            }
          }
          .rs-button {
            a {
              margin-top: 0;
              background: var(--rs-white-color);
              color: #181818;
              border: none;
            }
          }
        }
        .image-wrapper {
          position: absolute;
          bottom: -8%;
          left: 0;
          @media screen and (min-width: $breakpoint-md) {
            left: 28%;
          }
          @media screen and (min-width: size(1024)) {
            left: 30%;
          }
          img {
            display: inline-block;
            vertical-align: bottom;
            height: size(200);
            @media screen and (min-width: $breakpoint-md) {
              width: size(430);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(525);
            }
          }
        }
      }
    }
  }
  .graphql-promo {
    .rs-container {
      .wrapper {
        .content-wrapper {
          .markdown {
            font-size: 0;
            line-height: 0;
            .p {
              font-size: size(16);
              line-height: size(24);
              font-weight: normal;
              padding-bottom: size(20);
              color: var(--rs-white-color);
              @media screen and (min-width: $breakpoint-lg) {
                padding-bottom: size(40);
              }
              em {
                color: #F5FF78;
              }
            }
          }
          .text-button {
            &.rs-button {
              .link {
                background: transparent;
                color: var(--rs-white-color);
                border-color: var(--rs-white-color);
                margin-top: 0;
                padding: size(12);
                @media screen and (min-width: $breakpoint-md) {
                  padding: size(14) size(24);
                }
                &:after {
                  border-color: var(--rs-white-color);
                }
              }
            }
          }
        }
      }
    }
  }
  .press-mentions {
    .image-wrap {
      &.press-image {
        .image-item {
          text-align: left;
        }
      }
    }
  }
  .rs-cricket-banner {
    .hero-links {
      .text-button {
        >a {
          margin-top: size(12);
          @media screen and (min-width: $breakpoint-md) {
            margin-top: size(18);
          }
        }
      }
    }
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        margin-top: size(60);
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-top: size(100);
      }
    }
  }
  .graph-promotion {
    .title {
      &.markdown {
        display: inline-block;
        color: var(--rs-black-color);
        font-size: size(30);
        font-weight: 800;
        line-height: size(44);
        padding-bottom: size(24);
        max-width: size(670);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }

        strong {
          color: var(--rs-lavender-color);
        }
      }
    }
    .graph-contents {
      display: block;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(40);
        display: flex;
        flex-wrap: wrap;
        gap: size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        background-image: url('~@/assets/graph-bg.svg');
        background-repeat: no-repeat;
        background-position: top 0 right size(200);
        gap: size(36);
      }

      >a {
        border-radius: size(6);
        border: 1px solid #F4F3F3;
        background: var(--rs-white-color);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        padding: size(24);
        position: relative;
        z-index: 100;
        margin-bottom: size(20);
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 40%;
          margin-bottom: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 size(190);
        }

        h3 {
          color: var(--rs-black-color);
          font-size: size(20);
          font-weight: 700;
          padding-bottom: size(8);
        }
        a {
          font-size: size(12);
          font-weight: 400;
          line-height: size(14);
          text-decoration-line: underline;
          margin-bottom: size(200);
          display: inline-block;
        }

        .image-item {
          position: absolute;
          bottom: size(16);
          right: 0;
          height: size(155);
          width: auto;
        }
        &.section-3 {
          .image-item {
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
  .client-testimonials-wrap {
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      .hero-title {
        strong {
          color: var(--rs-lavender-color);
        }
      }
      img {
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: inline-block;
          max-width: size(320);
        }
      }
    }
    .testimonial-wrapper {
      display: flex;
      align-items: start;
      justify-content: center;
      gap: size(16);
      flex-wrap: wrap;
      padding-top: size(20);
      flex-direction: column;
      @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        padding-top: size(40);
      }
      .testimonial {
        border-radius: size(18);
        background: #FFF;
        box-shadow: 0px 0px 30px 0px rgba(141, 151, 158, 0.20);
        padding: size(24);
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(300);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(210);
        }
        .title-container {
          display: flex;
          align-items: center;
          gap: size(12);
          .image-item {
            max-width: size(20);
            img {
              max-height: size(20);
            }
            @media screen and (min-width: $breakpoint-md) {
              max-width: size(40);
              img {
                max-height: size(40);
              }
            }
          }
          .title {
            strong {
              display: block;
              font-size: size(12);
              font-weight: 400;
              line-height: normal;
              padding-top: size(4);
            }
          }
        }
        .desc {
          display: block;
          padding-top: size(20);
          border-top: 1px solid #ECECEC;
          margin-top: size(20);
          font-size: size(16);
          font-weight: 400;
          line-height: size(24);
        }
      }
    }
  }
}
</style>
