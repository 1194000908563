<template>
  <div :class="['super-menu-outer', sport, {'sports-menu': (!sport)}, {'paygo-menu': isPaygo},
    {'rzmenu': isRzWebsite}, {'have-rz-sub-menu': showRoanuzSubMenu}, {'menu-expanded': expandMenu}]">
    <div class="content" v-if="!isRzWebsite">
      <div class="super-menu" :class="{'super-menu-expanded': expandMenu}">
        <div class="logo-box">
          <a :href="sport === 'cricket' ? 'https://www.cricketapi.com' : 'https://sports.roanuz.com'"
          :title="sport === 'cricket' ? 'Cricket API' : 'Roanuz Sports'">
            <img v-if="sport === 'cricket'" class="logo" src="@/assets/rca-logo-black.svg" alt="Cricket API Logo" />
            <img v-else class="logo" src="@/assets/rs-logo.svg" alt="Roanuz Sports Logo" />
          </a>
        </div>
        <div class="nav-box"
            :class="[{expanded: expandMenu, 'begin-collapse': beginCollapse, },
                      overlayMasterMenu ? 'add-scroll-overlay' : 'add-scroll']">
          <div class="get-started-link">
            <a v-if="sport" class="link-placeholder" @click="fireEvent('home-header-gs')"
              href="https://www.cricketapi.com/get-started/" title="Get Started" rel="noopener">Get Started</a>
          </div>
          <MasterMenuNavMain :showDownArrow="false" :menuItems="menuItems" :activeLink="activeLink"/>
          <nav class="nav-main support-wrapper">
            <ul>
              <li class="with-sub-menu">
                <div class="link-item">
                  <span class="link-placeholder">
                    Support
                  </span>
                </div>
                <div class="sub-menu-items expanded">
                  <ul data-v-81304c4a="" class="master-sub-menu support-model">
                    <li>
                      <div class="link-item">
                        <span class="support-icon hide-on-mobile">
                          <img src="@/assets/message-icon-colored.svg" alt="message-icon">
                        </span>
                        <a href="mailto:support@cricketapi.com" target="_self" class="link-plain"
                          title="Support Mail">
                          support@cricketapi.com
                        </a>
                      </div>
                      <div class="desc-area">
                        <span class="markdown">
                          <span class="show-on-mobile">
                            <img src="@/assets/message-icon-colored.svg" alt="message-icon"></span>
                          <span class="left-space">Send us message</span>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="link-item">
                        <span class="support-icon hide-on-mobile">
                          <img src="@/assets/phone-icon-us.svg" alt="Phone-Call">
                        </span>
                        <a :href="supportNumbers.usTollfree.link" target="_self" class="link-plain"
                          title="US Tollfree Number">{{supportNumbers.usTollfree.label}}</a>
                      </div>
                      <div class="desc-area">
                        <span class="markdown">
                          <span class="show-on-mobile">
                            <img src="@/assets/phone-icon-us.svg" alt="Phone-Call"></span>
                          <span class="left-space">US Tollfree Number</span>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="link-item">
                        <a href="https://www.cricketapi.com/support-and-usage-plans/"
                          target="_self" class="link-plain support-plans-link"
                          title="Support & Usage Plans">Support & Usage Plans</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
          <div class="actions">
            <div class="items show-on-desktop">
              <template v-if="sport">
                <router-link v-if="isPaygo"
                  :to="{ name: 'projectCreation' }" class="btn focus"
                  title="Get Started" rel="noopener">
                  Get Started
                </router-link>
                <a v-else class="btn focus" @click="fireEvent('home-header-gs')"
                  href="https://www.cricketapi.com/get-started/" title="Get Started" rel="noopener">
                  Get Started
                </a>
              </template>
              <ConsoleButton :isPaygo="isPaygo" v-else />
            </div>
          </div>
        </div>
        <div class="more-action-box cric-home" :class="{expanded: expandMenu}">
          <button class="action-button" aria-label="Burger Menu"
           v-if="sport" @click="toggleBodyScroll()">
            <BlueMenuIcon :state.sync="moreActionState"/>
          </button>
          <ConsoleButton :isPaygo="isPaygo" v-else />
        </div>
      </div>
    </div>
    <div class="content rz-menu" v-else>
      <div class="super-menu rz-menu"
        :class="[{'super-menu-expanded': expandMenu}, {'have-rz-sub-menu': showRoanuzSubMenu}]">
        <div class="logo-box">
          <a v-if="sport" class="logo-wrap" href="/"
          :title="'Roanuz'">
            <img v-if="sport === 'cricket'" class="logo" src="@/assets/rca-logo-black.svg" alt="Cricket API Logo" />
          </a>
          <a v-else class="logo-wrap rz" :href="'http://roanuz.com/'"
          :title="'Roanuz'">
            <span>
              <img class="logo" src="@/assets/rz-website/rzlogo.png" alt="Roanuz Logo" />
            </span>
            <span class="logo-text">Roanuz</span>
          </a>
        </div>
        <div class="nav-box"
          :class="[sport, { expanded: expandMenu, 'begin-collapse': beginCollapse, },
            overlayMasterMenu ? 'add-scroll-overlay' : 'add-scroll']">
          <MasterMenuNavMain :isRzMenu="isRzWebsite" :showDownArrow="false"
            :menuItems="menuItems" :activeLink="activeLink"/>
          <div class="actions sign-in-button">
            <div class="items show-on-desktop">
              <ConsoleButton />
            </div>
          </div>
        </div>
        <div class="mobile-header-actions" :class="{expanded: expandMenu}">
          <div class="icon-wrapper">
            <a :href="supportNumbers.indianNumber.link" target="_self" class="link-plain"
              title="Indian Support Number">
              <img src="@/assets/rsCricket/phone_in_talk.svg" alt="Contact button"/>
            </a>
            <a
              :href="consoleLink"
              :title="bootData && bootData.user && bootData.user.name ? 'Console' : 'Sign in'"
              rel="noopener">
              <img src="@/assets/rsCricket/account_circle.svg" alt="Profile icon"/>
            </a>
          </div>
          <div class="more-action-box cric-home" :class="{expanded: expandMenu}">
            <button class="action-button" aria-label="Burger Menu" @click="toggleBodyScroll()">
              <BlueMenuIcon :state.sync="moreActionState"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

  body.disable-scroll {
    overflow-y: hidden;
  }
  @mixin sub-menu-item($color) {
    color: var(--regular-text);
    &:hover {
      color: $color;
    }
  }

  @mixin support-menu-item($color) {
    a {
      color: var(--regular-text);
      &:hover {
        color: $color;
      }
    }
  }
  .super-menu-outer {
    --super-menu-container-height: #{size(84)};
    padding: size(15) 0 size(5) 0;

    --master-bg: #fff;

    --super-sub-item-bg1: #6B53FF;
    --super-sub-item-bg2: #FE646F;
    --super-sub-item-bg3: #FFBE16;
    --super-sub-item-bg4: #0BB8CB;
    --super-sub-item-text: #525252;

    --super-support-text1-color: #7476FE;
    --super-support-text2-color: #EF707E;
    --super-support-text3-color: #FBBE2C;

    --logo-height: #{size(28)};
    --logo-left-pad: #{size(16)};
    --logo-box-width: auto;
    --more-action-box-width: #{size(52)};
    --more-action-button-height: #{size(50)};
    --product-link-bottom-pad: #{size(35)};
    --cricket-products-box-width: #{size(460)};
    --product-link-max-width:  #{size(210)};
    --super-menu-z-index: 1001;

    @media screen and (min-width: $breakpoint-md) {
      padding: size(25) 0 size(10) 0;
      --logo-height: #{size(42)};
      --logo-left-pad: #{size(20)};
      --more-action-box-width: #{size(72)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --logo-height: #{size(43)};
      &.cricket {
        --logo-height: #{size(51)};
      }
      --logo-left-pad: #{size(24)};
      --logo-box-width: #{size(250-16)};
    }

    background: var(--master-bg);

    &.sports-menu {
      transition: all .3s linear;
      border-bottom: size(1) solid var(--ca-border-color);
      z-index: var(--super-menu-z-index);
      &.overlay-menu:not(.super-menu-expanded) {
        top: calc(var(--super-menu-container-height) * -1);
      }
      .super-menu {
        .btn-spinner .btn-spinner-dot:before {
          background-color: var(--hero-text);
        }
      }
      .content {
        max-width: var(--home-page-header-max-width);
        margin: 0 auto;
        &.rz-menu {
          max-width: size(1080);
        }
      }
    }
    &.cricket {
      transition: all .3s linear;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      box-shadow: 0 size(3) size(10) var(--regular-text-inv);
      padding-top: size(5);
      z-index: var(--master-menu-z-index);
      .content {
        max-width: var(--home-page-header-max-width);
        margin: 0 auto;
        &.rz-menu {
          max-width: size(1080);
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        padding: 0;
        .nav-main {
          >ul >li {
            padding: 1.5rem 0 1.5rem;
            & > .link-item {
              .link-placeholder, a {
                font-size: size(18);
                line-height: size(22);
              }
              &:after {
                display:block;
                content: '';
                border-bottom: size(3) solid var(--ca-btn-bg);
                transform: scaleX(0);
                transition: transform 400ms ease-in-out;
              }
              &:hover:after {
                transform: scaleX(1);
              }
            }
          }
        }
        .nav-box .actions {
          .btn {
            font-size: size(18);
            line-height: size(22);
          }
        }
      }
      &.overlay-menu:not(.super-menu-expanded) {
        transition: all .3s ease-in-out;
        z-index: var(--master-menu-z-index);
        box-shadow: 0 0 size(10) rgba(0, 0, 0, 0.1);
        @media screen and (min-width: $breakpoint-md) {
          box-shadow: 0 size(3) size(10) rgba(0, 0, 0, 0.1);
        }
      }
      &.overlay-menu {
        .sub-menu-items.expanded {
          top: size(85);
          box-shadow: 0 size(4) size(5) rgba(0, 0, 0, 0.15);
          &.Solutions , &.Docs, &.Pricing, &.GraphQL{
            top: size(65);
          }
          &.Solutions {
            .link-item {
              &.graphql {
                .link-plain {
                  display: inline-block;
                  width: size(98);
                  position: relative;
                  &:after {
                    content: 'New';
                    display: inline-block;
                    font-size: size(10);
                    padding: size(1) size(5);
                    border-radius: size(3);
                    color: #fff;
                    margin-left: size(5);
                    background:
                    linear-gradient(90deg,#0000 33%,rgba(253, 239, 239, 0.3) 50%,#0000 66%)
                      green;
                    position: absolute;
                    top: 0;
                    right: size(-25);
                    background-size:300% 100%;
                    animation: shine 1.5s infinite;
                    @media screen and (min-width: $breakpoint-md) {
                      right: size(-8);
                    }
                  }
                  @keyframes shine {
                    0% {
                      background-position: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .nav-main {
        .sub-menu-items {
          // right: 22%;
          @media screen and (min-width: $breakpoint-md) {
            transform: translateX(-30%);
          }
          @media screen and (min-width: $breakpoint-lg) {
            transform: translateX(-30%);
          }
          &.expanded {
            &.Docs, &.Pricing{
              @media screen and (min-width: $breakpoint-md) {
                transform: translateX(-22%);
                transition: none;
                animation: none;
              }
            }
            &.Pricing {
              @media screen and (min-width: $breakpoint-md) {
                width: size(145);
              }
            }
            &.Solutions {
              .link-item {
                &.graphql {
                  .link-plain {
                    display: inline-block;
                    width: size(98);
                    position: relative;
                    &:after {
                      content: 'New';
                      display: inline-block;
                      font-size: size(10);
                      padding: size(1) size(5);
                      border-radius: size(3);
                      color: #fff;
                      margin-left: size(5);
                      background:
                      linear-gradient(90deg,#0000 33%,rgba(253, 239, 239, 0.3) 50%,#0000 66%)
                        green;
                      position: absolute;
                      top: 0;
                      right: size(-25);
                      background-size:300% 100%;
                      animation: shine 1.5s infinite;
                      @media screen and (min-width: $breakpoint-md) {
                        right: size(-8);
                      }
                    }
                    @keyframes shine {
                      0% {
                        background-position: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .support-wrapper {
        display: none;
      }
      .super-menu {
        .nav-box {
          .nav-main {
            .master-sub-menu {
              li {
                &.paygo_new {
                  width: 100%;
                  max-width: initial;
                  background-color: var(--onboard-bg-color);
                  margin: size(5) 0;
                  &:hover {
                    animation: jump-up-shadow 350ms ease-out;
                  }
                  .link-box {
                    padding: size(16);
                    &:hover {
                      animation: none;
                    }
                  }
                  .link-item {
                    margin-bottom: size(8);
                    color: #181818;
                    .link-text {
                      border-left: none;
                      &:after {
                        content: "(New)";
                        display: inline-block;
                        font-size: size(13);
                        line-height: size(24);
                        padding-left: size(5);
                        font-weight: bold;
                        color: var(--onboard-link-color);
                      }
                    }
                  }
                  .desc-area {
                    display: block;
                    padding-right: size(15);
                    padding-left: size(7);
                    .markdown {
                      p {
                        font-size: size(14);
                        line-height: size(17);
                        margin-right: 20px;
                      }
                    }
                  }
                  @media screen and (min-width: $breakpoint-md) {
                    margin: size(20) 0 0 0;
                    .desc-area {
                      .markdown {
                        p {
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.cricket {
            .support-no {
              display: none;
            }
            .sign-in-button {
              display: none;
            }
          }
          .actions {
            .btn {
              background: var(--ca-btn-bg);
            }
          }
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        .super-menu {
          .nav-box {
            .nav-main {
              .master-sub-menu {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-between;
                li {
                  font-family: var(--regular-font);
                  padding: size(7);
                  &:first-child {
                    padding-top: 0;
                  }
                  &.paygo_new {
                    margin-top: size(30);
                    background-image: url('../../assets/paygo-new-banner-img.png');
                    background-repeat: no-repeat;
                    background-position: right size(30) center;
                    background-size: size(70) size(110);
                    .link-box {
                      padding: size(30);
                    }
                    .desc-area {
                      display: flex;
                      justify-content: space-between;
                      .markdown {
                        flex: 0 0 size(300);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.rzmenu {
      transition: all .3s linear;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding: size(7) 0 size(7) 0;
      z-index: var(--master-menu-z-index);
      background: var(--rz-highlight-color);
      box-shadow: none;
      border-bottom: none;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(40);
        padding-bottom: 0;
        .nav-main {
          >ul >li {
            padding: size(18) 0 0rem;
            .link-item a {
              font-size: var(--rz-link-reg-font-size);
              line-height: var(--rz-link-reg-line-height);
              font-weight: bold;
              color: var(--rz-link-color);
              &:hover {
                @include jump-up-animation;
              }
            }
          }
        }
      }
      &.have-rz-sub-menu {
        position: initial;
        background-color: #131313;
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
        &.menu-expanded {
          z-index: var(--dailog-z-index);
          position: fixed;
          right: 0;
          left: 0;
          top: 0;
        }
        .nav-main {
          >ul >li {
            .link-item a {
              color: var(--rz-highlight-color);
            }
          }
        }
        .super-menu {
          .logo-text {
            color: var(--rz-highlight-color);
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          position: initial;
          padding-top: 0;
        }
      }
      &.overlay-menu:not(.super-menu-expanded) {
        top: size(0);
        box-shadow: 0 size(3) size(10) rgba(0, 0, 0, 0.1);
      }
      &.cricket {
        padding: 0;
        @media screen and (max-width: size(450)) {
          padding: size(6) 0;
        }
        &.overlay-menu {
          top: size(0);
        }
      }
      &.overlay-menu {
        padding-top: 0;
      }
    }
    .nav-main {
      .sub-menu-items {
        @media screen and (min-width: $breakpoint-md) {
          position: absolute;
        }
      }
    }
  }

  .more-action-box {
    &.expanded {
      &.cric-home {
        height: auto;
      }
     .menu-svg {
        display: none;
      }
    }
    &.cric-home {
      height: size(50);
        svg {
          width: size(28);
        }
        .default-fill {
          fill: #1A1A1A;
      }
    }
  }

  .mobile-header-actions {
    display: flex;
    align-items: center;
    gap: size(20);
    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }

    &.expanded {
      display: inline;
      .icon-wrapper {
        display: none;
      }
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      gap: size(20);
    }
  }

  .super-menu {
    justify-content: space-between;
    &:not(.super-menu-expanded) {
      padding: 0 size(15);
      display: flex;
    }
    .nav-box {
      &.expanded {
        margin-top: size(15);
        padding: size(40) size(20) 0 size(20);
        box-shadow: 0 0 size(10) rgba(0, 0, 0, 0.1);
        .nav-main {
          ul {
            margin: 0;
            .sub-menu-items {
              box-shadow: none;
              height: initial;
              animation: none;
              .desc-area {
                display: none;
              }
            }
            .master-sub-menu {
              padding: 0;
              li {
                padding-left: 0;
              }
            }
            li {
              // border-bottom: 1px solid var(--divider);

              &:last-child {
                border-bottom: 0;
              }

              &.active, &.active-parent {
                font-weight: bold;
              }

              .link-item {
                display: flex;
                justify-content: space-between;
                &.paygo_link {
                  justify-content: initial;
                  a {
                    padding-right: size(3);
                  }
                }
              }

              .btn-submenu, .icon-submenu {
                width: var(--dropdown-icon-width);
                height: var(--dropdown-icon-width);
                margin: auto 0;
              }

              .icon-submenu {
                display: none;
                padding-left: size(5);
              }

              a, .link-placeholder {
                padding: size(13) size(13) size(13) 0;
                display: block;
                color: var(--regular-text-inv);
                cursor: pointer;
                &.link-box {
                  padding: 0;
                }
                &:hover {
                  @include jump-up-animation;
                  // font-weight: bold;
                }
              }
            }
          }
          .link-item {
            font-size: size(16);
            font-weight: bold;
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(20);
              font-weight: 600;
            }
          }
          &.support-wrapper {
            display: block;
            border-top: size(1) solid var(--ca-border-color);
            margin-top: size(10);
            .master-sub-menu {
              li {
                .desc-area {
                  display: block;
                }
              }
            }
            .link-placeholder {
              display: none;
            }
            .with-sub-menu {
              padding-top: size(20);
              .link-item {
                padding-left: 0;
                border-left: none;
              }
              .master-sub-menu {
                padding: 0;
              }
            }
            .desc-area {
              padding: size(4) 0;
            }
          }
        }
      }
    }
    &.super-menu-expanded {
      display: flex;
      flex-direction: column;
      padding-right: size(15);
      padding-left: size(15);
      padding-top: size(3.5);
      .more-action-box {
        .action-button {
          order: 1;
          position: absolute;
          right: size(15);
          top: size(5);
        }
      }
      .nav-box.expanded {
        order: 2;
      }
    }
    &.rz-menu {
      .nav-box {
        &.expanded {
          .nav-main {
            ul {
              li {
                &.support-mail, &.support-no-mobile {
                  padding-bottom: size(15);
                  a {
                    color: var(--rz-hero-color);
                  }
                }
              }
              .left-space {
                padding-left: size(10);
              }
            }
          }
        }
        .actions {
          box-shadow: none;
          &.sign-in-button {
            &.hide-on-desktop {
              box-shadow: none;
              display: inline-table;
            }
          }
        }
        &.add-scroll-overlay {
          background: var(--regular-text-inv);
        }
      }
      &.have-rz-sub-menu {
        .nav-box {
          &.add-scroll-overlay {
            background: transparent;
          }
          &.expanded {
            .nav-main {
              ul {
                .left-space {
                  padding-left: size(10);
                  color: var(--rz-highlight-color);
                }
                li {
                  &.support-mail, &.support-no-mobile {
                    a {
                      color: var(--rz-highlight-color);
                    }
                  }
                }
              }
            }
          }
          .nav-main {
            .master-sub-menu {
              li {
              .link-item {
                  .link-text {
                    color: var(--rz-highlight-color);
                  }
                  @media screen and (min-width: $breakpoint-md) {
                    .link-text {
                      color: var(--regular-text);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      padding: 0 size(30);
      &.rz-menu {
        .nav-box {
          .nav-main {
            ul {
              li {
                &.support-mail, &.support-no-mobile {
                  display: none;
                }
              }
            }
          }
          .actions {
            &.sign-in-button {
              &.hide-on-desktop {
                display: none;
              }
            }
          }
          &.add-scroll-overlay {
            background: transparent;
          }
        }
        .nav-main {
          > ul > li {
            .icon-submenu {
              display: none;
            }
          }
        }
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
      &.rz-menu {
        &:not(.super-menu-expanded) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .logo-box {
      @include flex-center;
      justify-content: left;

      .logo {
        height: var(--logo-height);
      }
    }

    .nav-box {
      flex-direction: column;
      justify-content: right;
      display: none;

      &.add-scroll {
        max-height: calc(100vh - #{size(186)} );
        overflow: auto;
        margin-bottom: #{size(40)};
        @media screen and (min-width: $breakpoint-md) {
          max-height: unset;
          overflow: initial;
          margin-bottom: 0;
        }
      }

      &.add-scroll-overlay {
        max-height: calc(100vh - #{size(122)} );
        overflow: auto;
        margin-bottom: #{size(40)};
        @media screen and (min-width: $breakpoint-md) {
          max-height: unset;
          overflow: initial;
          margin-bottom: 0;
        }
      }
      &.expanded {
        display: flex;
      }

      animation: drop-menu 350ms ease-out;

      &.begin-collapse {
        animation: drop-menu-rev 450ms ease-out forwards;
      }

      .nav-main {
        --regular-text-inv: #333;
        --dropdown-icon-width: #{size(16)};
        overflow: initial;
        @media screen and (min-width: $breakpoint-md) {
          --dropdown-icon-width: #{size(16)};
          overflow: auto;
        }

        @media screen and (min-width: $breakpoint-lg) {
          --dropdown-icon-width: #{size(9)};
        }
        .sub-menu-items {
          z-index: var(--dailog-z-index);
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
        }
        .master-sub-menu {
          margin: 0;
          padding: size(15) size(5);
          li {
            padding: size(10);
            font-family: var(--lato-font);
            &:first-child {
              padding-top: size(0);
            }
            &:last-child {
              padding-bottom: size(20);
              @media screen and (min-width: $breakpoint-md) {
                padding-bottom: unset;
              }
            }
            .link-item {
              padding: 0;
              border-bottom: none;
              .link-text {
                @include sub-menu-item(var(--super-sub-item-bg1));
              }
              a {
                padding: 0;
                text-transform: none;
                font-size: size(18);
                line-height: size(22);
                font-weight: 600;
                @media screen and (min-width: $breakpoint-md) {
                  font-size: size(14);
                  line-height: size(20);
                }
                .link-text {
                  padding: 0;
                  margin-bottom: size(0);
                  display: block;
                  font-size: size(15);
                  line-height: size(17);
                  border-left: none;
                  @media screen and (min-width: $breakpoint-md) {
                    padding: 0 size(7);
                    font-size: size(14);
                    line-height: size(18);
                  }
                }
              }
            }
            .desc-area {
              padding: 0 0 0 size(10);
              font-size: size(15);
              line-height: size(18);
              font-weight: normal;
              color: var(--super-sub-item-text);
            }

            &:nth-child(2n) .link-item {
              .link-text {
                @include sub-menu-item(var(--super-sub-item-bg3));
              }
            }
            &:nth-child(3n) .link-item {
              .link-text {
                @include sub-menu-item(var(--super-sub-item-bg2));
              }
            }
            &:nth-child(4n) .link-item {
              .link-text {
                @include sub-menu-item(var(--super-sub-item-bg4));
              }
            }
          }
          &.support-model {
            @media screen and (min-width: $breakpoint-md) {
              padding: size(40) size(36);
            }
            @media screen and (min-width: $breakpoint-lg) {
              padding: size(35) size(36) size(30);
            }
            li {
              padding: size(10) 0;
              .link-item {
                padding-right: 0;
                padding-left: 0;
              }
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                padding-bottom: 0;
              }
              @media screen and (min-width: $breakpoint-md) {
                .desc-area {
                  padding-left: 0;
                }
              }
            }
          }
        }
        &.support-wrapper {
          @media screen and (min-width: $breakpoint-md) {
            text-align: center;
          }
          .left-space {
            padding-left: size(8);
            display: inline-block;
          }
          .master-sub-menu {
            li{
              .link-item {
                margin-bottom: 0;
                border-left: none;
                .support-icon:not(.hide-on-mobile) {
                  padding-bottom: 10px;
                  display: block;
                }
                .support-icon.hide-on-mobile {
                  padding-bottom: size(5);
                }
                @media screen and (min-width: $breakpoint-md) {
                  .link-plain {
                    display: block;
                  }
                }
              }
              &:nth-child(n) .link-item {
                @include support-menu-item(var(--super-support-text1-color));
              }
              &:nth-child(2n) .link-item {
                @include support-menu-item(var(--super-support-text2-color));
              }
              &:nth-child(3n) .link-item {
                @include support-menu-item(var(--super-support-text3-color));
              }
            }
          }
          .with-sub-menu {
            margin-bottom: size(40);
            @media screen and (min-width: $breakpoint-md) {
              margin-bottom: 0;
            }
            .link-item {
              .btn-submenu {
                display: inline-block;
              }
              .link-placeholder {
                cursor: pointer;
                font-size: size(16);
              }
              .support-plans-link {
                font-weight: normal;
                font-size: size(18);
                line-height: size(28);
                color: var(--ca-title-color);
                display: block;
                padding-bottom: 0;
                text-decoration-line: underline;
                &:hover {
                  color: var(--ca-btn-bg) !important;
                }
                @media screen and (min-width: $breakpoint-md) {
                  padding-top: size(10);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  font-size: size(20);
                }
              }
              a {
                font-size: size(18);
                line-height: size(24);
                font-weight: 600;
                margin-bottom: #{size(8)};
              }
              .desc-area {
                span {
                  font-size: size(15);
                  line-height: size(18);
                }
              }
            }
            @media screen and (max-width: $breakpoint-lg) {
              .master-sub-menu:not(.support-model) {
                padding: size(20);
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: size(20);
        &.sign-in-button {
          &.hide-on-desktop {
            display: flex;
          }
          .items {
            margin: 0;
          }
          .btn {
            &.btn--outline {
              text-transform: initial;
              font-size: var(--rz-link-reg-font-size);
              line-height: var(--rz-link-reg-line-height);
              font-weight: bold;
              color: var(--rz-highlight-color) !important;
              background: var(--rz-cta-color);
              border-radius: size(5);
              padding: size(10) size(20);
              border: size(1) solid var(--rz-cta-color);
            }
          }
        }

        // Indicator for .main overflow scroll
        box-shadow: 0 size(-2) size(20) size(5) var(--master-bg);

        .items {
          margin: auto;
        }
        .btn {
          display: block;
          margin-bottom: size(12);
          border-color: var(--ca-btn-bg);
          color: var(--regular-text-inv);
          &--outline {
            color: var(--ca-btn-bg) !important;
            background: var(--regular-text-inv);
            border-width: size(2);
            border-style: solid;
            text-align: center;
            min-width: size(60);
          }
          &:not(&--outline):hover {
            color: var(--ca-btn-bg);
            background: var(--regular-text-inv);
          }
        }
      }
    }

    .get-started-link {
      padding: size(10) 0;
      font-size: size(20);
      font-weight: 600;
      display: inline-block;
      &:hover {
        @include jump-up-animation;
      }
      & a:hover {
        color: var(--regular-text);
      }
    }

    .more-action-box {
      .action-button:focus  {
        outline: 0;
      }
      @media screen and (min-width: $breakpoint-md) {
        flex: 0 0 var(--more-action-box-width);
      }
      @include flex-center;
      .action-button {
        height: var(--more-action-button-height);
      }
      &.expanded {
        svg:not(.svg-icon svg) {
          display: none;
        }
      }
      .btn {
        &.btn--outline {
          font-size: size(16);
          min-width: size(60);
          text-align: center;
        }
      }
    }

    .logo-wrap {
      display: flex;
      align-items: center;
      &.rz {
        span {
          padding-top: size(8);
        }
      }
    }

    .logo-text {
      font-size: var(--rz-brand-type-reg-font-size);
      line-height: var(--rz-brand-type-reg-line-height);
      font-weight: 800;
      color: var(--rz-brand-type-color);
      padding-left: size(10);
      text-transform: capitalize;
      padding: size(10);
    }

    @media screen and (min-width: $breakpoint-md) {
      .nav-box {
        .actions {
          margin-bottom: size(28);
        }
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      --btn-pad-top: #{size(8)};
      --btn-pad-left:  #{size(16)};
      --logo-height: #{size(40)};
      .more-action-box {
        .btn--outline {
          color: var(--ca-btn-bg);
          font-weight: 600;
          border-width: size(2);
          text-transform: initial;
          letter-spacing: 0;
        }
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      .logo-box {
        flex: 0 0 var(--logo-box-width);
      }

      .nav-box {
        display: flex;
        flex-direction: row;
        justify-content: right;
        position: initial;
        background: none;
        animation: none;

        &.begin-collapse {
          animation: none;
        }

        .actions {
          margin-right: var(--junior-menu-pad-right);
          justify-content: center;
          margin-bottom: 0;
          box-shadow: none;
          &.sign-in-button {
            &.hide-on-desktop {
              display: none;
            }
            .btn {
              &.btn--outline {
                font-size: var(--rz-link-reg-font-size);
                line-height: var(--rz-link-reg-line-height);
                font-weight: bold;
                color: var(--rz-link-color) !important;
                background: var(--rz-cta-light-color);
                border-radius: size(5);
                padding: size(10) size(20);
                border: size(1) solid var(--rz-cta-light-color);
              }
            }
          }

          .items {
            // margin: auto 0;
            display: flex;
            flex-direction: row-reverse;
          }

          .btn {
            margin-left: size(20);
            margin-bottom: 0;
            padding: size(7) size(14);
            text-transform: initial;
            font-weight: 600;
            font-size: size(16);
            line-height: size(19);
            letter-spacing: initial;
            &.btn--outline {
              padding: size(5) size(14);
            }
          }
        }
      }

      .more-action-box {
        display: none;
      }

      .get-started-link {
        display: none;
      }

      &.rz-menu {
        .nav-main {
          > ul {
            >li {
              .link-placeholder {
                padding: 0;
              }
              &.with-sub-menu {
                .link-placeholder {
                  font-size: var(--rz-link-reg-font-size);
                  line-height: var(--rz-link-reg-line-height);
                  color: var(--rz-link-color);
                  font-weight: bold;
                  padding-bottom: size(22);
                }
                &#products-list, &#company-list {
                  display: block;
                  @media screen and (min-width: $breakpoint-md) {
                    display: none;
                  }
                }
                .GraphQL {
                  span {
                    &.link-placeholder {
                      display: inline-block;
                      width: size(98);
                      &:after {
                        content: 'New';
                        display: inline-block;
                        font-size: size(10);
                        padding: size(1) size(5);
                        border-radius: size(3);
                        color: #fff;
                        margin-left: size(5);
                        background:
                        linear-gradient(90deg,#0000 33%,rgba(253, 239, 239, 0.3) 50%,#0000 66%)
                          green;
                        position: absolute;
                        background-size:300% 100%;
                        animation: shine 1.5s infinite;
                      }
                      @keyframes shine {
                        0% {
                          background-position: right;
                        }
                      }
                    }
                  }
                }
                .master-sub-menu {
                  li, li:nth-child(2n), li:nth-child(3n) {
                    .link-item {
                      .link-text {
                        color: var(--regular-text);
                      }
                    }
                  }
                }
                &:hover {
                  .sub-menu-items {
                    position: absolute;
                  }
                }
              }
            }
          }
        }
        &.have-rz-sub-menu {
          .nav-main {
            > ul {
              >li {
                &.with-sub-menu {
                  .link-placeholder {
                    color: var(--rz-highlight-color);
                  }
                }
              }
            }
          }
        }
      }

      .nav-main {
        display: flex;
        align-items: center;
        > ul {
          margin: size(0);
          >li {
            display: inline-block;
            margin-top: size(3);
            border-bottom: none;
            $_border-height: size(3);
            &.with-sub-menu > .link-item {
              &:after {
                display: block;
                content: '';
                border-bottom: solid $_border-height var(--ca-btn-bg);
                transform: scaleX(0);
                transition: transform 400ms ease-in-out;
              }
              &:hover:after {
                transform: scaleX(1);
              }
            }
            .link-item {
              border-bottom: 0 solid transparent;
              transition: 300ms ease-in;
              display: inline-block;
              font-weight: 600;
              padding: 0 size(10);
              .link-placeholder {
                .icon-submenu {
                  display: none;
                }
              }
            }

            &.active, &.active-parent {
              .link-item {
                border-color: var(--master-highlight);
              }
            }

            .btn-submenu, .icon-submenu {
              width: var(--dropdown-icon-width);
              height: var(--dropdown-icon-width);
              margin: auto 0;
            }

            .icon-submenu {
              display: none;
              padding-left: size(5);
            }

            .btn-submenu {
              display: none;
            }
            .icon-submenu {
              transition: 400ms ease-in;
              display: inline-block;
              opacity: 1;
            }

            a, .link-placeholder {
              line-height: size(32);
              display: inline-block;
              padding: $_border-height size(5) size(22) size(5);
              &.link-box {
                line-height: initial;
                padding: 0;
              }
              &.active, &:hover {
                color: var(--regular-text-inv);
                cursor: pointer;
                background: inherit;
                animation: none;
              }

              &.active {
                font-weight: bold;
              }
            }

            &.with-sub-menu {
              ul li {
                display: block;
              }
              .link-placeholder {
                font-size: size(16);
                line-height: size(32);
              }
              &:hover {
                .icon-submenu {
                  opacity: 0;
                }
                .sub-menu-items {
                  display: block;
                  animation: drop-menu-item 400ms ease-in;
                  @media screen and (min-width: $breakpoint-lg) {
                    animation: drop-menu-item-large 400ms ease-in;
                  }
                  ul {
                    animation: opacity-fill 400ms ease-in 300ms forwards;
                  }
                }
                .link-item {
                  &::after {
                    transform: scaleX(1);
                  }
                }
              }
            }
          }
        }
      }

      .sub-menu-items {
        min-width: size(116);
        position: fixed;
        background: var(--master-bg);
        display: none;
        height: auto !important;

        &.expanded {
          animation: none;
        }

        ul {
          opacity: 0;
        }
        animation: drop-menu 500ms ease-in reverse;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      .nav-box {
        .actions {
          margin-right: 0;
        }
      }
    }
  }
  .super-menu-outer {
    &.overlay-menu {
      transition: all .3s linear;
      .super-menu-expanded {
        .more-action-box {
          .action-button {
            top: size(5);
          }
        }
      }
    }
    &.sports-menu {
      @media screen and (min-width: $breakpoint-md) {
        .nav-main > ul li.with-sub-menu {
          &:hover {
            .sub-menu-items {
              animation: drop-menu-item 400ms ease-in !important;
              transform: translateX(-30%);
            }
          }
        }
       }
    }
  }
  @keyframes drop-menu-item {
    0% {
      opacity: 0;
      transform: translate(-30%, 0);
    }
    50% {
      opacity: 1;
      // top: size(50);
    }
    100% {
      transform: translate(-30%,  0);
    }
  }
  @keyframes drop-menu-item-large {
    0% {
      opacity: 0;
      transform: translate(-30%, 0);
    }
    50% {
      opacity: 1;
      // top: size(50);
    }
    100% {
      transform: translate(-30%,  0);
    }
  }
  .super-menu-outer {
    &.plans-navigation-bar {
      .content {
        display: var(--ca-toggle-third-navigation-section);
        li#paygo_new {
          display: none;
        }
      }
      border-bottom: 0;
      padding-top: 0;
      .super-menu {
        justify-content: flex-end;
      }
      .nav-main {
        >ul >li {
          & > .link-item {
            &:after {
              display:block;
              content: '';
              border-bottom: size(3) solid var(--ca-btn-bg);
              transform: scaleX(0);
              transition: transform 400ms ease-in-out;
            }
            &:hover:after {
              transform: scaleX(1);
            }
            a {
              font-size: 1.125rem;
            }
          }
        }
      }
      .support-wrapper, .actions, .logo-box {
        display: none;
      }
    }
  }
  .rsArticleDetailView, .rsArticleList, .rsArticleArchives {
    .super-menu-outer {
      display: none;
    }
    .super-menu-outer {
      &.sports-menu {
        &.rzmenu {
          display: none;
        }
      }
    }
  }
</style>
<script>
import { mapState } from 'vuex';
import { supportNumbers } from '@/store/modules/page';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import BlueMenuIcon from '@/components/InteractiveIcons/BlueMenuIcon.vue';
import ConsoleButton from '@/components/Utils/ConsoleButton.vue';
import MasterMenuNavMain from '@/components/MasterMenuNavMain.vue';
import Config from '@/config';

export default {
  components: { BlueMenuIcon, MasterMenuNavMain, ConsoleButton },
  computed: {
    ...mapState({
      overlayMasterMenu: (state) => state.overlayMasterMenu,
      activeProject: (state) => state.project.activeProject,
      bootData: (state) => state.boot.instance,
    }),
  },
  props: {
    menuItems: {
      type: Array,
    },
    sport: {
      type: String,
    },
    isPaygo: {
      type: Boolean,
      default: false,
    },
    isRzWebsite: {
      type: Boolean,
      default: false,
    },
    showRoanuzSubMenu: {
      type: Boolean,
      default: false,
    },
    activeLink: String,
  },
  data() {
    return {
      beginCollapse: false,
      expandMenu: false,
      moreActionState: null,
      supportNumbers,
      consoleLink: Config.consoleHost,
    };
  },

  watch: {
    $route() {
      setTimeout(() => {
        this.moreActionState = SmartMorphAnimationState.Init;
      }, 455);
    },
    moreActionState(newVal) {
      this.beginCollapse = !newVal.isEnd;
      if (!newVal.isEnd) {
        setTimeout(() => {
          this.beginCollapse = false;
          this.expandMenu = newVal.isEnd;
        }, 455);
      } else {
        this.expandMenu = newVal.isEnd;
      }
    },
  },

  methods: {
    toggleBodyScroll() {
      if (!this.beginCollapse) {
        document.body.classList.add('disable-scroll');
      } else {
        document.body.classList.remove('disable-scroll');
      }
    },
    fireEvent(event) {
      window.dataLayer.push({ event });
    },
  },
};
</script>
