<template>
  <div class="rs-coverage" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource coverage'" :maxWidth="'max-width-600'"></Banner>
        </section>
        <section v-if="section.static_section_id == 'section_1'">
          <div class="rs-container">
            <h4>{{section.name}}</h4>
            <div class="flex-box">
              <div class="coverage-content">
                <content-section
                  :section="section.detail"
                  :showButton="false"
                  :sectionType="'tech-process-wrap rs-page max-width-320'"/>
              </div>
              <div class="pricing-table">
                <div class="price-table metrics">
                  <div class="table-row">
                    <div class="table-head one">Metrics</div>
                    <div class="table-head">Metric Group 100 <label>(MG100)</label></div>
                    <div class="table-head">Metric Group 100B <label>(MG100B)</label></div>
                    <div class="table-head">Metric Group 101 <label>(MG101)</label></div>
                  </div>
                  <div class="table-row" v-for="(item,index) in metrics" :key="index">
                    <div class="table-cell one">{{item.metrics}}</div>
                    <div class="table-cell" :class="item.mg_100">{{item.mg_100}}</div>
                    <div class="table-cell" :class="item.mg_100b">{{item.mg_100b}}</div>
                    <div class="table-cell" :class="item.mg_101">{{item.mg_101}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div class="pricing-table tour-and-leagues">
        <h2 class="rs-hero-title">
          List of Tournaments &amp; Series
        </h2>
        <div class="filters-and-search">
          <div class="search-wrapper">
            <h3 class="title">Search</h3>
            <input v-model="searchInput" type="text" :placeholder="'Enter full tournament name'" />
          </div>
          <div class="filter-wrapper">
            <div class="association filter">
              <h3 class="title">Association</h3>
              <div class="dropdown-wrapper">
                <select
                  class="dropdown"
                  @change="getAssociation($event.target.value)"
                >
                  <option value="" selected>All Associations</option>
                  <option
                    v-for="(association, i) in associations"
                    :key="i"
                    class="dropdown__list"
                  >{{association}}
                  </option>
                </select>
              </div>
            </div>
            <div class="metric-group filter">
              <h3 class="title">Metric Group</h3>
              <div class="dropdown-wrapper">
                <select
                  class="dropdown"
                  @change="getMetricGroup($event.target.value)"
                >
                  <option value="" selected>All Metric Groups</option>
                  <option
                    v-for="metricGroup in metricGroups"
                    :key="metricGroup.key"
                    :value="metricGroup.key"
                    class="dropdown__list"
                  >{{metricGroup.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="price-table">
          <div class="table-row">
            <div v-for="(header, index) in headers" :key="index" class="table-head
            ">{{header}}</div>
          </div>
          <div class="table-row" v-for="(item,index) in filteredList" :key="index">
            <div v-for="(cell, i) in item" :key="i" class="table-cell">
              {{cell}}
            </div>
          </div>
        </div>
      </div>
      <section v-for="(section, index) in page.sections" :key="index">
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import { mapState } from 'vuex';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import coverage from '../../../stories/CoverageList.json';
import metrics from '../../../stories/MetricData.json';
import csvData from '../../../stories/UpdatedCoverage.csv';


export default {
  name: 'rsCricketHome',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    ContentSection,
    PaymentSignupSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      dropdown: (state) => state.user.activedropdown,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
    filteredList() {
      if (this.csvData?.length > 0) {
        return this.csvData.filter((dataItem) => {
          const matchesSearch = !this.searchInput
          || dataItem['List of Tournaments & Series'].toLowerCase().includes(this.searchInput.toLowerCase());

          const matchesAssociation = !this.selectedAssociaton
          || dataItem.Association === this.selectedAssociaton;

          const matchesMetricGroup = !this.selectedMetricGroup
          || dataItem['Metric Group'] === this.selectedMetricGroup;

          return matchesSearch && matchesAssociation && matchesMetricGroup;
        });
      }
      return [];
    },
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      coverage,
      metrics,
      csvData: [],
      headers: [],
      searchInput: '',
      showDropdown: false,
      selectedDropdown: null,
      metricGroups: [
        {
          name: 'Metric Group 100',
          key: 'MG100',
        },
        {
          name: 'Metric Group 101',
          key: 'MG101',
        },
        {
          name: 'Metric Group 100 B',
          key: 'MG100B',
        },
      ],
      associations: [],
      selectedAssociaton: null,
      selectedMetricGroup: null,
      showFilters: false,
    };
  },

  async created() {
    try {
      await this.loadCsvData();
    } catch (err) {
      this.error = 'Error loading CSV data';
      console.error('CSV loading error:', err);
    }
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    async loadCsvData() {
      try {
        const response = await fetch(csvData);
        const text = await response.text();

        this.csvData = this.csvToJson(text);

        this.headers = Object.keys(this.csvData[0] || {});

        this.csvData.map((data) => {
          // eslint-disable-next-line dot-notation
          this.associations.push(data['Association']);
          this.associations = [...new Set(this.associations)];
          return this.associations;
        });
      } catch (error) {
        console.error('Error loading CSV file:', error);
      }
    },

    csvToJson(csv) {
      const lines = csv.split('\n').filter((line) => line.trim() !== '');
      if (lines.length < 2) return [];

      const headers = this.parseCsvLine(lines[0]);
      const data = lines.slice(1).map((line) => {
        const values = this.parseCsvLine(line);
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index] || '';
          return obj;
        }, {});
      });

      return data;
    },

    parseCsvLine(line) {
      const regex = /(".*?"|[^,]+)(?=\s*,|\s*$)/g;
      return [...line.matchAll(regex)].map((match) => match[0].replace(/^"|"$/g, ''));
    },
    changeCurrency() {
      this.showDropdown = false;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    setFilters() {
      this.showFilters = !this.showFilters;
    },
    getAssociation(value) {
      this.selectedAssociaton = value;
    },
    getMetricGroup(value) {
      this.selectedMetricGroup = value;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-coverage {
  font-family: var(--rz-hero-font);
  .price-table {
    border: size(1) solid var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    margin-bottom: size(100);
    overflow-x: scroll;
    display: block;
    .table {
      &-cell, &-head {
        display: table-cell;
      }
      &-head {
        background: var(--rs-light-blue-color);
        font-weight: bold;
        padding: size(10);
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        vertical-align: middle;
        color: var(--rs-black-color);
        &:nth-child(1) {
          background: var(--rs-black-color);
          color: var(--rs-white-color);
          padding-left: size(25);
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
      }
      &-row {
        display: table-row;
        &:hover {
          border: size(1) solid #c4c4c4;
          box-shadow: 0 size(2) size(5) rgba(0, 0, 0, 0.05);
          background: var(--rs-white-color);
          .table-cell {
            border-right: size(1) solid var(--rs-white-color);
          }
        }
        &:nth-child(2) {
          .table-cell {
            padding-top: size(18);
          }
        }
        &:last-child {
          .table-cell {
            padding-bottom: size(18);
          }
        }
      }
      &-cell {
        padding: size(12) size(10);
        border-right: size(1) solid #EBE9E9;
        vertical-align: middle;
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        color: var(--rs-cta-blue-color);
        min-width: size(100);
        &:last-child {
          border-right: size(1) solid transparent;
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
        &.broadcast {
          color: var(--rs-pink-color);
        }
        &.available {
          color: var(--rs-gray-color);
        }
        &.one {
          min-width: size(220);
          max-width:  size(220);
          color: var(--rs-black-color);
          padding-left: size(25);
        }
      }
    }
    &.metrics {
      max-width: 90vw;
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(675);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: initial;
        margin: initial;
      }
      .table {
        &-head {
          padding: size(20);
          &.one {
            padding-left: size(30);
          }
          &:last-child {
            padding-right: size(35);
          }
        }
        &-row {
          &:last-child {
            .table-cell {
              color: var(--rs-pink-color);
              &.one {
                color: var(--rs-black-color);
              }
            }
          }
        }
        &-cell {
          border-right: size(1) solid transparent;
          padding-left: size(20);
          &.one {
            min-width: size(150);
            max-width: size(150);
            font-weight: 500;
            padding-left: size(30);
          }
        }
      }

    }
    label {
      display: block;
      font-weight: normal;
    }
  }
  .tour-and-leagues {
    max-width: size(1400);
    margin: 0 auto;
    .rs-hero-title {
      max-width: initial;
      padding: 0 0 size(20) 0;
      text-align: center;
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
    }
    padding-top: size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(50);
    }
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
    text-align: center;
    max-width: size(800);
    margin: 0 auto;
    padding-bottom: size(80);
  }
  @media screen and (min-width: size(1400)) {
    .price-table {
      display: table;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .flex-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .filters-and-search {
    display: flex;
    flex-direction: column;
    gap: size(20);
    margin: 0 size(20) size(28);
    @media screen and (min-width: $breakpoint-md) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    @media screen and (min-width: size(1400)) {
      margin: 0 0 size(40);
    }

    .title {
      font-size: size(12);
      padding-bottom: size(8);
      color: var(--rs-black-color);
    }
    .search-wrapper {
      @media screen and (min-width: $breakpoint-md) {
        flex: 1 0 60%;
      }
      input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: size(8) size(10) size(8) size(36);
        border: size(1) solid #c4c4c4;
        border-radius: size(6);
        font-size: size(14);
        color: var(--rs-black-color);
        background: url('../../assets/search.svg') no-repeat left size(10) center;
        background-size: size(18);
        outline: none;
        &::placeholder {
          color: var(--rs-gray-color);
          font-size: size(14);
        }
      }
    }
  }

  .filter-wrapper {
    display: flex;
    gap: size(20);
    flex-direction: column;
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      align-items: center;
    }

    .filter {
      width: 100%;
    }
  }

  .dropdown-wrapper {
    select {
      display: block;
      width: 100%;
      font-size: size(14);
      line-height: size(16);
      -webkit-appearance: none;
      &::-ms-expand {
        display: none;
      }
      appearance: none;
      background: url('../../assets/icon-dropdown-blk.svg') no-repeat transparent;
      background-size: size(9) size(6);
      background-position: top size(15) right size(10);
      box-shadow: none;
      outline: none;
      border: size(1) solid #c4c4c4;
      padding: size(10) size(24) size(10) size(10);
      border-radius: size(6);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (min-width: $breakpoint-md) {
        max-width: size(250);
      }

      option {
        margin-top: size(5);
        background: rgba(255, 255, 255, 0.9);
        padding: 0 size(12) size(8) size(12);
        transition: height .3s, visibility .3s;
        transform: translateY(-6px);
        cursor: default;

        &:hover {
          color: var(--ca-btn-bg);
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.rs-coverage {
  .coverage-content {
    .markdown {
      blockquote {
        span {
          font-size: size(14) !important;
          line-height: size(22) !important;
          color: var(--rs-gray-color) !important;
        }
      }
    }
  }
}
</style>
