<template>
  <div :class="[section.static_section_id.split('_').join('-')]" class="promo">
    <div class="rs-container">
      <div class="wrapper banner-wrapper">
        <div class="content-wrapper">
          <h6 :class="[section.detail.links[1].element_class === 'text' ? '': 'flag-text']"
            v-if="section.detail.links && section.detail.links[1] && section.detail.links[1].name">
            {{section.detail.links[1].name}}</h6>
          <rz-markdown class="hero-title" v-if="section.detail.name"
            :text="section.detail.name"></rz-markdown>
          <rz-markdown v-if="section.detail.desc" class="text" :text="section.detail.desc"></rz-markdown>
          <TextButton :buttonType="'rs-button'" :key="section.detail.links[0].name"
            :link="section.detail.links[0]" />
        </div>
        <div class="image-wrapper">
          <rz-image-item class="banner-artwork"
            v-if="section.detail.img" :image="section.detail.img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  props: {
    section: Object,
  },
  components: {
    TextButton,
  },
};
</script>
<style lang="scss">
.banner-wrapper {
    .content-wrapper {
        .hero-title {
            display: inline-block;
            font-weight: 800;
            font-size: size(30);
            line-height: size(42);
            padding-bottom: size(10);
            padding-right: 0;
            color: #97E6AB;
            @media screen and (min-width: $breakpoint-md) {
                font-size: size(60);
                line-height: size(74);
            }
            strong {
                font-weight: bold;
                font-size: size(30);
                line-height: size(42);
                padding-right: 0;
                color: var(--rs-white-color);
                padding-bottom: 0;
                @media screen and (min-width: $breakpoint-md) {
                    font-size: size(60);
                    line-height: size(74);
                }
            }
        }
        .text {
            .p {
                font-size: size(16);
                line-height: size(24);
                color: var(--rs-white-color);
                padding-bottom: size(30);
            }
        }
        .text-button.rs-button .link {
            padding: 0;
            margin: 0;
            color: var(--rs-white-color);
            border: none;
            outline: none;
            &:after {
                border-color: var(--rs-white-color);
                margin-left: size(10);
            }
        }
    }
}
.betting-odds-promo {
    &.promo {
        .rs-container {
            padding-top: size(32);
            @media screen and (min-width: $breakpoint-md) {
                padding-bottom: 0;
            }
            .wrapper {
                background: #2D2F9D;
                border-radius: size(6);
                padding: size(30) size(20) 0;
                @media screen and (min-width: $breakpoint-md) {
                    padding: size(50) size(20) size(30);
                }
                @media screen and (min-width: size(1024)) {
                    padding-bottom: 0;
                }
                @media screen and (min-width: $breakpoint-lg) {
                    padding: size(60) size(60) 0;
                }
                .text-button.rs-button .link {
                    padding: 0;
                    margin: 0;
                    color: var(--rs-white-color);
                    border: none;
                    outline: none;
                    &:after {
                        border-color: var(--rs-white-color);
                        margin-left: size(10);
                    }
                }
                .content-wrapper {
                    @media screen and (min-width: size(1024)) {
                        padding-bottom: size(50);
                    }
                    @media screen and (min-width: $breakpoint-lg) {
                        padding-bottom: size(60);
                    }
                }
                .image-wrapper {
                    height: size(350);
                    @media screen and (min-width: $breakpoint-md) {
                        height: size(400);
                    }
                }
            }
        }
    }
}
.ants-promo {
   &.promo {
    .rs-container {
        padding-top: size(32);
        @media screen and (min-width: $breakpoint-md) {
            padding-bottom: 0;
        }
      .wrapper {
        position: relative;
        background-color: #010101;
        border-radius: size(6);
        padding: size(30) size(20);
        @media screen and (min-width: $breakpoint-md) {
            padding: size(40) size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
            padding: size(60);
        }
        .flag-text {
            clip-path: none;
            background: none;
            width: auto;
            margin: 0 0 size(10);
            padding: 0;
            color: #D0F37B;
            font-size: size(16);
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            display: inline-block;
            @media screen and (min-width: size(1024)) {
                font-size: size(24);
            }
        }
        .hero-title {
            display: block;
            .p {
                color: var(--rs-white-color);
                font-size: size(20);
                line-height: size(28);
                @media screen and (min-width: $breakpoint-lg) {
                    font-size: size(40);
                    line-height: size(50);
                }
            }
        }
        .text-button.rs-button .link {
            padding: 0;
            margin: 0;
            color: var(--rs-white-color);
            border: none;
            outline: none;
            &:after {
                border-color: var(--rs-white-color);
                margin-left: size(10);
            }
        }
        .image-wrapper {
            @media screen and (min-width: $breakpoint-md) {
                position: absolute;
                right: 0;
                bottom: 0;
                height: size(365);
                padding-left: 0;
                height: 100%;
            }
            @media screen and (min-width: size(1024)) {
                height: size(300);
            }
            @media screen and (min-width: $breakpoint-md) {
                height: 100%;
            }

            .banner-artwork {
                width: 98%;
                @media screen and (min-width: size(1024)) {
                    width: size(600);
                }
                @media screen and (min-width: $breakpoint-lg) {
                    width: size(464);
                }
            }
        }
      }
    }
  }
}

.ipl-2025-api {
  &.promo {
    .rs-container {
       padding: size(32) size(20) size(10);
      .wrapper {
        background-color: #ECE9FF;
        display: flex;
        flex-direction: column;
        padding: size(30) size(20);

        .content-wrapper {
          .flag-text {
            background: transparent;
            width: 100%;
            clip-path: none;
            font-size: size(14);
            font-weight: 200;
            line-height: normal;
            font-style: normal;
            padding: 0;
            font-family: inherit;
            text-transform: capitalize;
            display: flex;
            gap: size(4);
            margin-bottom: size(16);
            margin-top: 0;
          }

          .hero-title {
            background: url('../../assets/ipl-2025-copy.svg') no-repeat;
            color: transparent;
            height: size(70);
            background-size: 100%;
            width: 100%;
            margin-bottom: size(10);
          }

          .text {
            .p {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              color: #111;
              line-height: 160%;
            }
          }

          .text-button {
            position: relative;
            a {
                &::after {
                    position: absolute;
                    top: 20px;
                    right: 18px;
                }
            }
            span {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
            background: #111;
            border-radius: size(4);
            padding: 16px;
            display: block;
            margin-bottom: size(30);
          }
        }

        .image-wrapper {
            padding-top: 0;
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: size(32) size(20) 0;
        .wrapper {
          flex-direction: row;
          padding: size(50) size(20) size(30);

          .content-wrapper {
            flex: 1;
            .flag-text {
                margin-bottom: size(24);
                margin-top: size(20);
                font-size: size(16);
            }
            .hero-title {
                height: size(80);
                background-size: 100%;
                display: block;

                .p {
                    height: inherit;
                }
            }

            .flag-text {
                align-items: center;

                &::before {
                    content: url('../../assets/ipl-2025-icon.svg');
                    display: block;
                    margin-top: size(4);
                }
            }

            .text-button {
                display: inline-block;
                margin-bottom: 0;
                position: relative;

                a {
                    &::after {
                        position: unset;
                    }
                }
            }
          }
        }
        .banner-wrapper {
          .image-wrapper {
            padding: 0;
            padding-left: 2rem;
            height: 22rem;
            margin-top: size(16);
          }
        }
      }

      @media screen and (min-width: $breakpoint-lg) {
        padding: size(32) 0 0 0;
        .wrapper {
          flex-direction: row;
          padding: size(50) size(32) size(30) size(60);

          .content-wrapper {
            flex: 0.8;
            .flag-text {
                margin-bottom: size(18);
            }
            .hero-title {
                height: size(111);
                width: 100%;
                background-size: 90% 100%;
                margin-bottom: size(20);
            }

            .text {
                .p {
                    padding-bottom: size(50);
                }
            }
          }
        }
        .banner-wrapper {
          .image-wrapper {
            flex: 0.2;
            height: 25rem;
            margin-top: 0;
            .banner-artwork {
              width: 24rem;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.banner-wrapper {
    background: #2D2F9D;
    border-radius: size(6);
    padding: size(30) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
        padding: size(30) size(20) size(50);
    }
    @media screen and (min-width: size(1024)) {
        padding-bottom: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
        padding: size(40) size(60);
    }
    .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
            width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
            width: size(440);
        }
        .text-img {
            display: inline-block;
            width: size(98);
            padding-bottom: size(10);
            padding-top: size(20);
        }
        h6 {
            font-size: size(14);
            line-height: size(20);
            color: var(--rs-white-color);
            margin-bottom: size(10);
            margin-top: size(20);
            &.flag-text {
                clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
                background: #97E6AB;
                text-transform: uppercase;
                -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
                width: size(73);
                font-size: size(10);
                line-height: size(14);
                font-weight: 600;
                color: black;
                padding: size(3) size(16) size(3) size(9);
            }
        }
    }
    .image-wrapper {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-top: size(30);
        width: 100%;
        height: size(350);
        @media screen and (min-width: $breakpoint-md) {
            padding-left: size(30);
            width: 45%;
            padding-top: size(75);
            height: size(400);
        }
        @media screen and (min-width: size(1024)) {
            padding-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(50);
            width: auto;
        }
        .banner-artwork {
            display: inline-block;
            vertical-align: bottom;
            @media screen and (min-width: $breakpoint-md) {
                width: 100%;
            }
            @media screen and (min-width: $breakpoint-lg) {
                width: size(420);
            }
        }
    }
}
</style>
