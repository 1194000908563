<template>
  <div class="rs-resource-home package-resource" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'resource_banner'">
          <Banner :banner="section" :maxWidth="'max-width-600'" :sectionType="'resource'"></Banner>
        </section>
      </section>
      <section class="rs-flex-box rs-container part-first space-between">
        <section class="change-order">
          <section v-for="(section, index) in page.sections" :key="'section'+index">
            <section class="what-can-build-wrap" v-if="section.static_section_id == 'section_1'">
              <div class="rs-container pad-15">
                <div class="rs-flex-box space-between">
                  <div>
                    <content-section
                      :section="section.detail"
                      :showButton="false"
                      :sectionType="'tech-process-wrap rs-page max-width-600'"/>
                    <div class="image-wrap max-width-600" v-if="section.detail.img">
                      <rz-image-item
                        v-if="section.detail.img" :image="section.detail.img" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_2'" :class="section.static_section_id">
              <div class="rs-container pad-15">
                <content-section
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"
                  :buttonType="'rs-button tick-btn'"/>
                <h4 class="pink-color">{{section.name}}</h4>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_3'" :class="section.static_section_id">
              <div class="rs-container pad-15">
                <content-section class="docs-wrapper"
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"
                  :buttonType="'rs-button'"/>
              </div>
            </section>
            <section v-if="section.static_section_id == 'resource_default'" :class="section.static_section_id">
              <div class="rs-container pad-15">
                <content-section
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"
                  :buttonType="'rs-button tick-btn'"/>
                <h4 v-if="section.name" class="pink-color">{{section.name}}</h4>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_4'" :class="section.static_section_id">
              <div class="rs-container pad-15">
                <h5>{{section.name}}</h5>
                <content-section
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"/>
                <div class="max-width-600">
                  <div class="top-padding" v-for="(item,index) in section.detail.items" :key="index">
                    <h6>{{item.name}}</h6>
                    <rz-markdown class="detail-text" v-if="item.desc" :text="item.desc"></rz-markdown>
                  </div>
                  <div class="image-wrap">
                    <img src="@/assets/rsCricket/client_logos.png" alt="Client logos"/>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
        <div v-if="resourceLoaded" class="match-wrap">
          <template v-if="resourceType !== 'ipl-2025-and-complete-historical-data'">
            <h4 class="match-wrap_currency">Pricing</h4>
            <div v-for="packagePlan in packagePlans" :key="packagePlan.key" class="package-pricing">
              <div v-if="packagePlan.name !== 'Business +' &&
                (packagePlan.name.split('-').join(' ').toLowerCase()
                === resourceType.split('-').join(' ').toLowerCase())">
                <div class="package-price">
                  <template v-if="packagePlan.show_early_bird_pricing">
                    <span class="flag-text">{{packagePlan.early_bird_pricing_notes ?
                      packagePlan.early_bird_pricing_notes : 'Early Bird Price'}}</span>
                  </template>
                  <p v-if="packagePlan.price.unit_amount[currency] >= 0"  class="highlighted-price">
                    {{formatCurrency(packagePlan.price.unit_amount[currency], currency)}}
                  </p>
                  <template v-if="!packagePlan.price.unit_label || packagePlan.price.unit_label === ''">
                    <span v-if="packagePlan.type === 'association_package'"
                    class="unit-label">Per month when billed annually</span>
                    <span v-else class="unit-label">For entire tournament</span>
                  </template>
                  <span v-else class="unit-label">{{packagePlan.price.unit_label}}</span>
                  <TextButton :buttonType="'rs-button cta'" :link="{'name': 'Get started',
                  'url': '/v5/user/project/review/?package='+packagePlan.key+'&step=ReviewPlan'}" />
                </div>
              </div>
              <div v-else-if="packagePlan.name === 'Business +' && resourceType === 'business+'">
                <div class="package-price">
                  <template v-if="packagePlan.show_early_bird_pricing">
                    <span class="flag-text">{{packagePlan.early_bird_pricing_notes ?
                      packagePlan.early_bird_pricing_notes : 'Early Bird Price'}}</span>
                  </template>
                  <p v-if="packagePlan.price.unit_amount[currency] >= 0"  class="highlighted-price">
                    {{formatCurrency(packagePlan.price.unit_amount[currency], currency)}}
                  </p>
                  <template v-if="!packagePlan.price.unit_label || packagePlan.price.unit_label === ''">
                    <span v-if="packagePlan.type === 'association_package'"
                    class="unit-label">Per month when billed annually</span>
                    <span v-else class="unit-label">For entire tournament</span>
                  </template>
                  <span v-else class="unit-label">{{packagePlan.price.unit_label}}</span>
                  <TextButton :buttonType="'rs-button cta'" :link="{'name': 'Get started',
                  'url': '/v5/user/project/review/?package='+packagePlan.key+'&step=ReviewPlan'}" />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <h4 class="contact-sales-title">
              Get a Quote
            </h4>
            <a
              :href="'/v5/contact-sales'" class="buy-btn">
              Contact Sales
            </a>
          </template>
        </div>
      </section>
      <section v-for="(section, index) in page.sections" :key="'endpint'+index">
        <section class="rs-container pad-100" v-if="section.static_section_id == 'get_started'">
          <get-started-section />
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
          <div class="rs-container">
            <support-wrapper />
          </div>
        </section>
      </section>
      <section v-for="(section) in page.sections" :key="section.static_section_id">
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { minAmountForDiscount } from '@/store/modules/page';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import { Package } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import GetStartedSection from '@/components/RsCricket/GetStartedSection.vue';

export default {
  name: 'rsCricketHome',
  props: {
    resourceType: String,
  },
  components: {
    CricketLoadingPlaceholder,
    TextButton,
    SupportWrapper,
    ContentSection,
    Banner,
    GetStartedSection,
    PaymentSignupSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
    apiresourceType() {
      return this.resourceType.replace(/-/g, '_');
    },
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchPackagePricing();
    });
  },
  data() {
    return {
      resources: null,
      api_usage: null,
      resourceLoaded: false,
      previousTabId: null,
      minAmountForDiscount,
      packagePlans: null,
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-package-${this.resourceType}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchPackagePricing();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchPackagePricing();
      });
    },
    fetchPackagePricing() {
      return Package.getPackagePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.packagePlans = resp.data.data.response.filter((plan) => plan.active && plan.show_in_website);
        this.resourceLoaded = true;
        this.packagePlans = this.packagePlans.reverse();
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-package-${this.resourceType}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-resource-home {
  font-family: var(--rz-hero-font);
  h6 {
    &.flag-text {
      font-size: size(14);
      line-height: size(20);
      color: var(--rs-white-color);
      margin-bottom: size(5);
      clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      background: #97E6AB;
      text-transform: uppercase;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      width: size(82);
      font-size: size(10);
      line-height: size(14);
      font-weight: 600;
      color: black;
      padding: size(3) size(16) size(3) size(9);
    }
  }
  .match-wrap {
    background: var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    width: size(330);
    padding: size(28);
    color: var(--rs-black-color);
    align-self: flex-start;
    margin: 0 size(20);
    order: 1;
    &_box {
      display: inline-block;
      width: calc(100% / 3);
      .inline {
        font-size: size(15);
        line-height: size(21);
        font-weight: bold;
      }
      label {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
        padding-bottom: size(4);
        display: block;
        color: #585757;
      }
    }
    &_currency {
      font-size: var(--rs-title-level3-font-size);
      line-height: var(--rs-title-level3-line-height);
      color: var(--rs-black-color);
      font-weight: bold;
    }
    &_per-month {
      font-size: var( --rs-sm-label-font-size);
      line-height: var( --rs-sm-label-line-height);
      .strike-price {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
      }
      &.color-gray {
        color: var(--rs-gray-color);
        padding-top: size(18);
        &.two {
          padding-bottom: size(10);
        }
        span {
          color: var(--rs-black-color);
          font-weight: bold;
          display: inline-block;
          &.strike-price {
            padding-bottom: 0;
          }
        }
      }
    }
    .pricing-wrapper {
      .unit-label {
        font-weight: 400;
        font-size: size(15);
        line-height: size(21);
        color: #777777;
        padding-top: size(5);
      }
      table {
        width: 100%;
        margin-top: size(20);
        thead {
          tr {
            text-align: left;
            th {
              a {
                font-size: size(13);
                line-height: size(18);
                color: var(--hero-text);
                font-weight: bold;
              }
            }
          }
        }
        tbody {
          tr {
            &:first-child {
              td {
                padding: size(14) 0 size(20);
              }
            }
            td {
              padding: size(20) 0;
              padding-right: size(5);
              border-bottom: size(1) solid #DADADA;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(20) 0;
              }
              a {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
                display: block;
                width: size(42);
                &:hover {
                  text-decoration: underline;
                }
              }
              h5 {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
              }
              p {
                font-weight: 700;
                font-size: size(13);
                line-height: size(18);
                color: var(--rs-black-color);
              }
              .free-card {
                color: #00A656;
                padding: size(1) size(8);
                background: rgba(151, 230, 171, 0.2);
                border-radius: size(3);
                font-size: size(12);
                line-height: size(17);
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: size(1024)) {
      position: sticky;
      top: 80px;
      margin: size(75) 0 0 0;
    }
  }
  .what-can-build-wrap {
    .image-wrap {
      height: size(400);
    }
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(35);
      }
    }
  }
  .discount-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(35);
      }
    }
  }
  .pad-15 {
    padding: size(15) size(20);
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(15) 0;
    }
  }
  .docs-wrapper {
    border-bottom: size(1) dashed var(--rs-gray-color);
    padding-bottom: size(30);
  }
  .max-width-600 {
    max-width: size(600);
  }
  .top-padding {
    padding-top: size(30);
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-white-color);
    font-weight: bold;
    &.pink-color {
      color: var(--rs-pink-color);
      max-width: size(600);
      padding-top: size(30);
    }
  }
  h5 {
    font-size: var(--rs-title-level2-font-size);
    line-height: var(--rs-title-level2-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
  }
  h6 {
    font-size: var(--rs-title-level4-font-size);
    line-height: var(--rs-title-level4-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
    font-family: var(--rz-hero-font);
    padding-bottom: size(10);
  }
  .detail-text {
    color: var(--rs-black-color);
    font-size: var(--rs-title-level4-font-size);
    line-height: size(25);
  }
  .pay-go-wrap {
    border-top: size(1) dashed var(--rs-gray-color);
  }
}
section {
  section {
    &.resource_default {
      border-bottom: size(1) dashed var(--rs-gray-color);
      .rs-container {
        padding-top: size(32);
        padding-bottom: size(32);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: size(32);
          padding-bottom: size(32);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(32) 0;
        }
      }
    }
    &.section_4 {
      .rs-container {
        padding-top: size(32);
      }
    }
  }
}


.change-order {
  order: 2;
  padding-top: size(30);
  @media screen and (min-width: size(1024)) {
    order: 1;
    padding-top: 0;
  }
}

.rs-container {
  &.part-first {
    position: relative;
    padding: 0 0 size(50) 0;
  }
}
h4 {
  &.contact-sales-title {
    font-size: size(20);
    line-height: size(24);
    color: var(--rs-black-color);
    margin-bottom: size(16);
  }
}
.buy-btn {
  display: flex;
  font-size: size(12);
  line-height: size(15);
  font-weight: 700;
  color: var(--rs-white-color);
  border: size(1) solid var(--hero-text);
  padding: size(12);
  background: var(--hero-text);
  border-radius: size(5);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    @include jump-up-animation;
  }
}
</style>

<style lang="scss">
.package-pricing {
  .highlighted-price {
    font-size: size(24);
    line-height: size(34);
    color: var(--rs-black-color);
    padding-top: size(20);
    font-weight: 700;
  }
  .text-button {
    &.rs-button {
      display: block;
      width: 100%;
      .link {
        display: block;
        font-size: size(12);
        line-height: size(15);
        font-weight: 700;
        color: var(--rs-white-color);
        border: size(1) solid var(--hero-text);
        padding: size(12) 0;
        background: var(--hero-text);
        border-radius: size(5);
        width: 100%;
        margin-top: size(16);
        text-align: center;
        cursor: pointer;
        &:hover {
          @include jump-up-animation;
        }
        &:after {
          border-color: var(--rs-white-color);
        }
      }
    }
  }

  .flag-text {
    padding: size(4) size(8);
    font-size: size(9);
    line-height: size(13);
    background:
    linear-gradient(90deg,#0000 33%,rgb(250 243 176) 50%,#0000 66%) #F6E337;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    font-weight: 600;
    color: var(--rs-black-color);
    border-radius: size(4);
    background-size:300% 100%;
    animation: shine 1.5s infinite;
    @keyframes shine {
      0% {
        background-position: right;
      }
    }
  }
  .unit-label {
    font-weight: 500;
    font-size: size(12);
    line-height: size(17);
    color: var(--rs-gray-color);
  }

  .package-price {
    .flag-text {
      margin: size(20) 0 size(8);
      display: inline-block;
    }
    .highlighted-price {
      padding-top: 0;
    }
    .rs-button {
      > a {
        margin-top: size(20);
      }
    }
  }
}

.package-resource {
  .resource_default {
    .quote-wrap {
      &.tech-process-wrap {
        .markdown {
          .p {
            padding-bottom: size(12);
          }
        }
      }
    }
  }
}

</style>
